import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  scrubSettingsBulkLogs: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  queryParams: null,
};

export const getScrubSettingsBulkLogs = createAsyncThunk(
  'scrubSettingsBulkLogs/getScrubSettingsBulkLogs',
  async (params, thunkAPI) => {
    const state = thunkAPI.getState();
    const savedParams = state.scrubSettingsBulkLogs.queryParams;
    const reqParams = params || savedParams;

    return handleAPI('platform/scrub_settings_bulk_log', 'get', reqParams);
  }
);

const scrubSettingsBulkLogSlice = createSlice({
  name: 'scrubSettingsBulkLogSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScrubSettingsBulkLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getScrubSettingsBulkLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.queryParams = action.meta.arg;
      state.scrubSettingsBulkLogs = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getScrubSettingsBulkLogs.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default scrubSettingsBulkLogSlice.reducer;
