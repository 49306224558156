import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeActionModal } from '../../../redux/slices/confirmationModalSlice';
import Modal from './Modal';

const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const {
    heading,
    description,
    action,
    cancelAction,
    closeAction,
    closeButtonText,
    actionButtonText,
  } = useSelector((state) => state.actionModal);

  const handleOnSubmit = async () => {
    setSubmitDisabled(true);
    try {
      await action();
    } finally {
      dispatch(closeActionModal());
      setSubmitDisabled(false);
    }
  };

  const handleClose = () => {
    if (closeAction) {
      closeAction();
    }
    dispatch(closeActionModal());
  };

  const handleCancel = () => {
    if (cancelAction) {
      cancelAction();
    }
    dispatch(closeActionModal());
  };

  return (
    <Modal
      id='deleteModal'
      cancelButtonTitle={closeButtonText}
      submitButtonTitle={actionButtonText}
      onSubmit={handleOnSubmit}
      onClose={handleClose}
      onCancel={handleCancel}
      submitButtonDisabled={submitDisabled}
      className='!max-w-[500px]'
    >
      <h2 className='mb-4 text-xl font-bold text-text'>{heading}</h2>
      <p className='text-base text-text'>
        {description ?? 'Only an admin will be able to restore.'}
      </p>
    </Modal>
  );
};

export default ConfirmationModal;
