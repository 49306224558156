import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  logs: [],
  totalPages: null,
  isLoading: true,
  error: null,
  totalCount: null,
  count: null,
};

export const getLogs = createAsyncThunk('logs/getLogs', async (types) => {
  const response = await handleAPI(
    `/platform/activity/${types.id}`,
    'get',
    types.params
  );
  return response;
});

const logsSlice = createSlice({
  name: 'logs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLogs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.logs = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getLogs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.totalPages = null;
      state.totalCount = null;
      state.count = null;
    });
  },
});

export default logsSlice.reducer;
