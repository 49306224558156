import React, { useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { handleAPI } from '../../../utils/api/api';
import Label from '../Label/Label';
import SelectTickIcon from '../../icons/SelectTickIcon';
import DownCaretThinIcon from '../../icons/DownCaretThinIcon';
import XIcon from '../../icons/XIcon';
import { deserialize } from 'deserialize-json-api';
import { deserializeData } from 'utils/helper/helper';

const findClosestModal = (ref) => {
  return ref?.closest('.modal') || null;
};

const determinePortalTarget = (ref, showOnBody, showOnModal) => {
  if (showOnBody) {
    return document.getElementById('body');
  } else if (showOnModal) {
    return findClosestModal(ref);
  }
  return null;
};

const MultiSelect = ({
  endPoint,
  selectedOptions,
  setSelectedOptions,
  isMulti,
  options,
  placeholder = 'Select from the list',
  infoData,
  label,
  include,
  displayValue = 'name',
  isDisabled = false,
  isRequired = false,
  isSearchable = true,
  sortOptions = true,
  isClearable = true,
  labelClassName,
  className,
  queryParams,
  menuPlacement = 'auto',
  showOnBody = false,
  showOnModal = true,
  searchBy = 'filter[name_cont]',
  filterRecordById,
  error,
  controllerField,
  mapAsyncDataFunc,
  defaultElement,
  onBlur,
  onChange,
  deserializer = 'jsona',
  defaultElementOnlyWhenData = false,
}) => {
  const [componentRef, setComponentRef] = useState(undefined);

  const deserializeLoadedOptions = (options) => {
    switch (deserializer) {
      case 'json-api':
        return deserialize(options).data;

      case 'jsona':
      default:
        return deserializeData(options);
    }
  };

  const loadOptions = async (searchQuery, _, { page }) => {
    const searchParam = searchBy;
    const response = await handleAPI(endPoint, 'get', {
      page: page,
      per_page: 20,
      ...(searchQuery && { [searchParam]: searchQuery }),
      'filter[s]': `${displayValue} asc`,
      include: include,
      ...queryParams,
    });

    let data = deserializeLoadedOptions(response.data);

    if (mapAsyncDataFunc) {
      data = mapAsyncDataFunc(data);
    }
    if (filterRecordById) {
      data = data.filter((item) => item?.id !== filterRecordById);
    }

    let returnData = include ? data[include] : data ?? response.data;

    const shouldIncludeDefault =
      defaultElement &&
      (!defaultElementOnlyWhenData || (returnData && returnData.length > 0));

    return {
      options: shouldIncludeDefault
        ? [...[{ [displayValue]: defaultElement, id: 0 }], ...returnData]
        : returnData,
      hasMore: data ? response.data.meta.total_count / 20 > page : false,
      additional: {
        page: page + 1,
      },
    };
  };

  const customStaticOptions = async (search) => {
    let optionsToDisplay;
    try {
      optionsToDisplay = Object.values(options).filter((option) => {
        const valueToFilter = option?.[displayValue]?.toLowerCase();
        return valueToFilter.includes(search.toLowerCase());
      });
    } catch (e) {
      // this saves a couple of hours of hair pulling if there's a bad item in the data
      console.error(e);
    }

    if (sortOptions) {
      optionsToDisplay.sort((a, b) => {
        const nameA = a?.[displayValue]?.toLowerCase();
        const nameB = b?.[displayValue]?.toLowerCase();
        return nameA.localeCompare(nameB);
      });
    }

    return {
      options: optionsToDisplay,
      hasMore: false,
    };
  };

  const handleOnChange = (data) => {
    controllerField ? controllerField.onChange(data) : setSelectedOptions(data);
    if (onChange) onChange(data);
  };

  const handleOnBlur = (e) => {
    controllerField?.onBlur(e);
    if (onBlur) onBlur(e);
  };

  const menuPortalTarget = determinePortalTarget(
    componentRef,
    showOnBody,
    showOnModal
  );

  return (
    <div
      ref={setComponentRef}
      className='relative'
    >
      {label && (
        <Label
          label={label}
          infoData={infoData}
          required={isRequired}
          className={labelClassName}
        />
      )}
      <AsyncPaginate
        loadOptions={endPoint ? loadOptions : customStaticOptions}
        isMulti={isMulti}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option[displayValue]}
        value={controllerField ? controllerField.value : selectedOptions}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        selectRef={controllerField?.ref}
        additional={{
          page: 1,
        }}
        isSearchable={isSearchable}
        closeMenuOnSelect={!isMulti}
        blurInputOnSelect={false}
        placeholder={placeholder}
        debounceTimeout={endPoint ? 500 : 0}
        components={{
          Option: CustomOption,
          MultiValue: CustomMultiValue,
          IndicatorsContainer: CustomIndicatorsContainer,
        }}
        className={`text-sm ${className}`}
        classNames={{
          control: (state) => `
            px-3 py-[0.5625rem] !min-h-auto !rounded-[5px] relative !bg-transparent
            ${state.isFocused && '!border-primary'}
            ${error ? '!border-error' : '!border-gray-5'}`,
          valueContainer: () => '!p-0',
          input: () => '!p-0 !m-0 [&>*]:!text-text',
          placeholder: () => '!text-gray-5 !m-0 !text-sm pr-2',
          singleValue: () => '!text-text !p-0 !m-0 !text-sm',
          menu: () =>
            `!shadow-none !bg-transparent !z-[10000] !my-0 ${showOnModal ? '!max-h-[150px]' : ''}`,
          menuList: () =>
            `!p-0 !rounded-[0.375rem] !z-50 !shadow-select-menu !bg-transparent ${showOnModal ? '!max-h-[150px]' : ''}`,
          noOptionsMessage: () => 'bg-gray-4',
          loadingMessage: () => 'bg-gray-4',
          menuPortal: () => '!z-[1000]',
        }}
        defaultMenuIsOpen={false}
        isDisabled={isDisabled}
        hideSelectedOptions={false}
        cacheUniqs={[endPoint, options]}
        menuPlacement={menuPlacement}
        menuPortalTarget={menuPortalTarget}
        isClearable={!isRequired && isClearable}
      />
      <p className='text-xs text-error'>{error}</p>
    </div>
  );
};

export default MultiSelect;

const CustomOption = ({ innerProps, children, isSelected, isMulti }) => {
  return (
    <div
      {...innerProps}
      className={`grid cursor-pointer grid-flow-col items-center gap-2 bg-gray-4 px-4 py-2 text-sm hover:!bg-gray-2 ${isSelected && 'font-medium'} ${isMulti ? 'gap-2' : 'justify-between'} `}
    >
      {isMulti && (
        <div
          className={`flex h-6 w-6 items-center justify-center rounded-full ${isSelected ? 'bg-primary' : 'border border-gray-1'} `}
        >
          {isSelected && (
            <SelectTickIcon
              className='stroke-white stroke-[2px]'
              size='sm'
            />
          )}
        </div>
      )}

      <div className='break-words'>{children}</div>

      {isSelected && !isMulti && <SelectTickIcon className='stroke-primary' />}
    </div>
  );
};

const CustomMultiValue = ({ children, selectProps }) => {
  const values = selectProps.value;
  const isLastValue = values[values.length - 1]?.name === children;
  return (
    <div>
      {children}
      {!isLastValue && ',\xa0'}
    </div>
  );
};

const CustomIndicatorsContainer = ({ selectProps, clearValue }) => {
  const shouldShowClearIcon =
    !selectProps.isRequired && selectProps.isClearable && selectProps.value;

  return (
    <div className='flex items-center'>
      {shouldShowClearIcon && (
        <div
          onTouchStart={clearValue}
          onClick={clearValue}
          className='group px-2'
        >
          <XIcon
            className='stroke-gray-1 group-hover:brightness-75'
            size='sm'
          />
        </div>
      )}
      <DownCaretThinIcon
        size='lg'
        svgClassName={`transition-all ${
          selectProps.menuIsOpen && 'rotate-180'
        }`}
        pathClassName={`!stroke-gray-1  ${
          selectProps.menuIsOpen && '!stroke-primary'
        }`}
      />
    </div>
  );
};
