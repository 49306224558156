import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import qs from 'qs';
import { getCookie, getUrlInfo } from 'utils/helper/helper';
import { handleAPI } from './api';

export const ODINFORGE_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://api-bi.swisscrm.com/api/'
    : 'https://staging-api.odinforgebi.com/api/';

const handleOdinForgeAPI = (
  path,
  method,
  params,
  data = null,
  responseType = 'json'
) => {
  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
  };

  let url = ODINFORGE_URL + path;

  if (params) {
    url = url + '?' + qs.stringify(params, { arrayFormat: 'brackets' });
  }

  return axios({
    method,
    url,
    ...(data && { data }),
    responseType,
    headers,
  });
};

export const openOdinForgeReports = async () => {
  const token = getCookie('token');
  if (!token) return;

  const userId = jwtDecode(token).user_id;
  const crmData = { user: { set_auto_login_token_expiry: true } };

  const apiResponse = await handleAPI(
    `platform/users/${userId}`,
    'PUT',
    '',
    crmData
  );

  const odinForgeData = {
    token: apiResponse.data.data.attributes.auto_login_token,
    tenant: getUrlInfo().subdomain,
  };

  const odinForgeResponse = await handleOdinForgeAPI(
    'user/autoLogin',
    'POST',
    '',
    odinForgeData
  );

  if (odinForgeResponse?.data?.status) {
    window.open(odinForgeResponse.data.redirectUrl, '_blank');
  }
};
