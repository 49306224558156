import React, { useEffect } from 'react';
import Select from '../../atoms/select/Select';
import PaginationButton from '../../atoms/PaginationButton/PaginationButton';
import PaginationCaret from '../../icons/PaginationCaret';
import PaginationDoubleCaret from '../../icons/PaginationDoubleCaret';
import { PaginationOptions } from 'constants/options/paginationOptions';

const Pagination = ({
  totalCount,
  totalPages,
  pagination,
  setPagination,
  paginationOptions = PaginationOptions.BASIC,
}) => {
  const { currentPage, perPage } = pagination;

  const handlePageCountChange = (state) => {
    setPagination({ perPage: state.value, currentPage: 1 });
  };

  const handlePageChange = (page) => {
    setPagination({ ...pagination, currentPage: page });
  };

  const getPageRange = () => {
    const pagesPerSide = 1;
    const pages = [currentPage];

    for (let i = 1; i <= pagesPerSide; i++) {
      if (currentPage - i > 0) {
        pages.unshift(currentPage - i);
      }
      if (currentPage + i <= totalPages) {
        pages.push(currentPage + i);
      }
    }

    const startPage = pages[0];
    const endPage = pages[pages.length - 1];

    return { pages, startPage, endPage };
  };

  const renderPageNumbers = () => {
    const { pages, startPage, endPage } = getPageRange();

    if (startPage > 1) {
      pages.unshift(1);
    }

    if (endPage < totalPages) {
      pages.push(totalPages);
    }

    return pages.map((page, index) => {
      const hasNumberGap = pages[index - 1] !== page - 1;
      const hasManyPages = totalPages > 1;
      const isSecondElement = index === 1;
      const isLastElement = index === pages.length - 1;

      const shouldRenderEllipsis =
        hasNumberGap && hasManyPages && (isSecondElement || isLastElement);

      return (
        <React.Fragment key={page}>
          {shouldRenderEllipsis && (
            <div className='h-8 w-8 pt-[6px] text-center text-[13px] font-semibold'>
              ...
            </div>
          )}
          <PaginationButton
            id={page === currentPage ? 'selected-page' : ''}
            key={page}
            content={page}
            selected={page === currentPage}
            onClick={() => handlePageChange(page)}
          />
        </React.Fragment>
      );
    });
  };

  const shouldRenderPrev = currentPage > 1;
  const shouldRenderNext = currentPage < totalPages;

  const startCount = (currentPage - 1) * perPage + 1;
  const endCount =
    currentPage * perPage >= totalCount ? totalCount : currentPage * perPage;

  useEffect(() => {
    setPagination((pagination) => ({ ...pagination, startCount, endCount }));
  }, [endCount, setPagination, startCount]);

  return (
    <div className='background flex items-center justify-between rounded-b-lg px-3'>
      <p className='text-sm text-gray-1'>
        Showing {startCount}-{endCount} of {totalCount} items
      </p>

      <div className='flex'>
        <div className='hidden items-center gap-2 md:flex'>
          <p className='text-sm text-gray-1'>Show</p>
          <Select
            setSelectedOptions={(value) => handlePageCountChange(value)}
            selectedOptions={{ key: perPage, value: perPage }}
            isSelectAll={false}
            options={paginationOptions}
            isSearch={false}
            position='top'
          />
        </div>

        <div className='my-3 ml-[1.125rem] flex justify-center gap-[5px] [&>*:not(#selected-page,.pgn-btn)]:hidden md:[&>*]:!block'>
          {shouldRenderPrev && (
            <>
              <PaginationButton
                content={<PaginationDoubleCaret className='rotate-180' />}
                className={'pgn-btn'}
                onClick={() => handlePageChange(1)}
              />
              <PaginationButton
                content={<PaginationCaret className='rotate-180' />}
                className={'pgn-btn'}
                onClick={() => handlePageChange(currentPage - 1)}
              />
            </>
          )}

          {renderPageNumbers()}

          {shouldRenderNext && (
            <>
              <PaginationButton
                content={<PaginationCaret className={'pgn-btn'} />}
                className={'pgn-btn'}
                onClick={() => handlePageChange(currentPage + 1)}
              />
              <PaginationButton
                content={<PaginationDoubleCaret className={'pgn-btn'} />}
                className={'pgn-btn'}
                onClick={() => handlePageChange(totalPages)}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Pagination;
