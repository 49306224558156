import React from 'react';
import TableCell from '../tableCell/TableCell';

const Loader = ({ rowCount, columnCount, spinner }) => {
  const rows = Array.from({ length: rowCount }).map((_, rowIndex) => (
    <tr key={`row-${rowIndex}`}>
      {Array.from({ length: columnCount }).map((_, colIndex) => (
        <TableCell key={`col-${rowIndex}-${colIndex}`}>
          {colIndex === columnCount - 1 ? (
            <div className='flex gap-2'>
              {Array.from({ length: 3 }).map((_, index) => (
                <div
                  key={index}
                  className='skeleton h-8 w-8 shrink-0 rounded-full'
                ></div>
              ))}
            </div>
          ) : (
            <div className='skeleton mb-2 h-4 w-32'></div>
          )}
        </TableCell>
      ))}
    </tr>
  ));

  return (
    <>
      {spinner ? (
        <div className='flex h-[calc(100vh-162px)] flex-col'>
          <div className='flex flex-grow items-center justify-center'>
            <span className='loading loading-spinner loading-lg text-primary'></span>
          </div>
        </div>
      ) : (
        <div className='table-shadow background mt-4 overflow-x-auto rounded-t-lg'>
          <table className='table-color table w-full'>
            <tbody>{rows}</tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Loader;
