import sidebarPaths from './sidebarPaths';
import productPaths from './productPaths';
import usersPaths from './userPaths';
import campaignPaths from './campaignPaths';
import paymentPaths from './paymentPaths';
import orderPaths from './orderPath';
import customerPath from './customerPaths';
import accountPaths from './accountPaths';

const paths = {
  ...sidebarPaths,
  ...productPaths,
  ...usersPaths,
  ...campaignPaths,
  ...paymentPaths,
  ...orderPaths,
  ...customerPath,
  ...accountPaths,
  login: '/login',
  forgetPassword: '/forget-password',
  resetPassword: '/reset-password',
  exportCsv: '/export-csv/download/:fileId',
  home: '/',
};

export default paths;
