import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const useCommanForm = (
  schema = null,
  defaultValues = null,
  shouldUnregister = null,
  schemaContext = null
) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    resetField,
    reset,
    setError,
    clearErrors,
    trigger,
    formState: {
      errors,
      isValid,
      touchedFields,
      isDirty,
      isSubmitted,
      submitCount,
    },
  } = useForm({
    mode: 'all',
    ...(schema && { resolver: yupResolver(schema), context: schemaContext }),
    defaultValues,
    shouldUnregister,
  });
  return {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    errors,
    control,
    resetField,
    reset,
    setError,
    clearErrors,
    isValid,
    trigger,
    touchedFields,
    isDirty,
    isSubmitted,
    submitCount,
  };
};

export default useCommanForm;
