import React, { useEffect, useRef, useState } from 'react';
import DatePickerButton from '../../atoms/DatePickerButton/DatePickerButton';
import { Box, ClickAwayListener, Popper } from '@mui/material';
import RangeCalendar from './RangeCalendar';
import { Controller } from 'react-hook-form';

const DateTimeRangePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  showTime = true,
  buttonClasses,
  control,
  watch,
  setToCustomRange,
}) => {
  const ref = useRef(null);
  const [openCalendar, setOpenCalendar] = useState(null);
  const [firstSelection, setFirstSelection] = useState(true);

  const startDateValue = watch ? watch('startDate') : value?.startDate;
  const endDateValue = watch ? watch('endDate') : value?.endDate;
  const rangeValue = { startDate: startDateValue, endDate: endDateValue };

  useEffect(() => {
    if (!openCalendar && !startDateValue) {
      setFirstSelection(true);
    }
  }, [openCalendar, startDateValue]);

  const calendarStart = control ? (
    <Controller
      name='startDate'
      control={control}
      render={({ field }) => (
        <RangeCalendar
          minDate={minDate}
          maxDate={maxDate}
          value={rangeValue}
          onChange={() => {
            if (firstSelection) {
              setOpenCalendar('end');
              setFirstSelection(false);
            }
          }}
          startOrEnd={'start'}
          field={field}
          setToCustomRange={setToCustomRange}
        />
      )}
    />
  ) : (
    <RangeCalendar
      minDate={minDate}
      maxDate={maxDate}
      value={rangeValue}
      onChange={(date) => {
        onChange({ ...rangeValue, startDate: date.startDate });
        if (firstSelection) {
          setOpenCalendar('end');
          setFirstSelection(false);
        }
      }}
      startOrEnd={'start'}
      setToCustomRange={setToCustomRange}
    />
  );

  const calendarEnd = control ? (
    <Controller
      name='endDate'
      control={control}
      render={({ field }) => (
        <RangeCalendar
          minDate={minDate}
          maxDate={maxDate}
          value={rangeValue}
          startOrEnd={'end'}
          field={field}
          setToCustomRange={setToCustomRange}
        />
      )}
    />
  ) : (
    <RangeCalendar
      minDate={minDate}
      maxDate={maxDate}
      value={rangeValue}
      onChange={(date) => onChange({ ...rangeValue, endDate: date.endDate })}
      startOrEnd={'end'}
      setToCustomRange={setToCustomRange}
    />
  );

  return (
    <ClickAwayListener onClickAway={() => setOpenCalendar(null)}>
      <Box>
        <div
          className='flex gap-3'
          ref={ref}
        >
          <div
            tabIndex={0}
            role='button'
          >
            <DatePickerButton
              className={`hidden lg:flex ${buttonClasses}`}
              placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
              isTime={showTime}
              value={rangeValue.startDate}
              startIcon
              onClick={() => setOpenCalendar('start')}
              isActive={openCalendar === 'start'}
              arrow
            />
          </div>
          <div
            tabIndex={1}
            role='button'
          >
            <DatePickerButton
              className={`hidden lg:flex ${buttonClasses}`}
              placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
              isTime={showTime}
              value={rangeValue.endDate}
              endIcon
              onClick={() => setOpenCalendar('end')}
              isActive={openCalendar === 'end'}
              arrow
            />
          </div>

          <Popper
            open={!!openCalendar}
            placement={'bottom'}
            anchorEl={ref.current}
            className={'relative !z-[1000]'}
            disablePortal
          >
            <div
              className={
                '!rounded-xl !bg-background shadow-[0px_3px_14px_2px_#0000001F,0px_8px_10px_1px_#00000024,0px_5px_5px_-3px_#00000033]'
              }
            >
              {openCalendar === 'start' && calendarStart}
              {openCalendar === 'end' && calendarEnd}
            </div>
          </Popper>
        </div>
      </Box>
    </ClickAwayListener>
  );
};

export default DateTimeRangePicker;
