import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal';
import Input from 'components/atoms/input/Input';
import * as yup from 'yup';
import useCommanForm from 'hooks/form/useCommanForm';
import { closePromptModal } from 'redux/slices/promptModalSlice';

const PromptModal = () => {
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const dispatch = useDispatch();
  const props = useSelector((state) => state.promptModal);

  const schema = yup.object().shape({
    input: yup
      .string()
      .required('This field is required')
      .when([], {
        is: () => props.inputRegex && props.inputError,
        then: (field) => field.matches(props.inputRegex, props.inputError),
      })
      .default(''),
  });

  const defaultValues = schema.default();

  const { register, errors, reset, handleSubmit, setValue, isValid } =
    useCommanForm(schema, defaultValues);

  useEffect(() => {
    if (props && props.initialValue) {
      setValue('input', props.initialValue);
    }
  }, [props, setValue]);

  const handleOnSubmit = async (data) => {
    try {
      setSubmitDisabled(true);
      await props.onConfirm?.(data.input);
    } finally {
      dispatch(closePromptModal());
      setSubmitDisabled(false);
    }
  };

  const handleClose = () => {
    reset(defaultValues);
    props.onClose?.();
    dispatch(closePromptModal());
  };

  const handleCancel = () => {
    props.onCancel?.();
    dispatch(closePromptModal());
  };

  return (
    <Modal
      id='promptModal'
      title={props.heading}
      cancelButtonTitle={props.closeButtonText}
      submitButtonTitle={props.confirmButtonText}
      onSubmit={handleSubmit(handleOnSubmit)}
      onClose={handleClose}
      onCancel={handleCancel}
      submitButtonDisabled={!isValid || submitDisabled}
      className='!max-w-[500px]'
    >
      <div className='grid gap-6'>
        <p className='text-text'>{props.description}</p>

        <Input
          register={register('input')}
          placeholder={props.inputPlaceholder}
          error={errors?.input?.message}
          autoFocus
        />
      </div>
    </Modal>
  );
};

export default PromptModal;
