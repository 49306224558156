import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  affiliates: [],
  affiliate: {},
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getAffiliates = createAsyncThunk(
  'affiliates/getAffiliates',
  async (params) => {
    const response = await handleAPI('platform/affiliates', 'get', params);
    return response;
  }
);

export const getAffiliate = createAsyncThunk(
  'affiliates/getAffiliate',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/affiliates/${id}`,
      'get',
      params
    );
    return response;
  }
);

const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    deleteAffiliate: (state, action) => {
      state.affiliates = state.affiliates.filter(
        (affiliate) => affiliate.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAffiliates.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAffiliates.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliates = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getAffiliates.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getAffiliate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliate = action.payload;
    });
  },
});

export const { deleteAffiliate } = affiliateSlice.actions;

export default affiliateSlice.reducer;
