import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
import CardBins from '../../components/pages/payment/binRouting/CardBins';
const Users = lazy(() => import('../../components/pages/admin/users/Users'));
const AddUser = lazy(
  () => import('../../components/pages/admin/users/AddUser')
);
const Logs = lazy(() => import('../../components/pages/admin/logs/Logs'));
const Smtps = lazy(
  () => import('../../components/pages/admin/plugins/smtp/Smtps')
);
const FulfilmentHouses = lazy(
  () =>
    import(
      '../../components/pages/admin/plugins/fulfilmentHouse/FulfilmentHouses'
    )
);
const AddFulfilmentHouse = lazy(
  () =>
    import(
      '../../components/pages/admin/plugins/fulfilmentHouse/AddFulfilmentHouse'
    )
);
const AdminAffiliates = lazy(
  () => import('../../components/pages/admin/Affiliates/AdminAffiliates')
);
const EditAffiliate = lazy(
  () => import('../../components/pages/admin/Affiliates/EditAffiliate')
);
const ScrubSettings = lazy(
  () => import('../../components/pages/admin/Affiliates/ScrubSettings')
);
const Pixels = lazy(
  () => import('../../components/pages/admin/Affiliates/Pixels')
);
const Publishers = lazy(
  () => import('../../components/pages/admin/Affiliates/Publishers')
);
const AffiliateCampaigns = lazy(
  () => import('../../components/pages/admin/Affiliates/AffiliateCampaigns')
);
const AffiliateScrub = lazy(
  () => import('../../components/pages/admin/Affiliates/PublisherScrub')
);
const Scrubsetting = lazy(
  () => import('../../components/pages/admin/Affiliates/AffiliateScrub')
);
const Blacklists = lazy(
  () => import('../../components/pages/admin/blacklist/Blacklists')
);
const Whitelists = lazy(
  () => import('../../components/pages/admin/whitelist/Whitelists')
);
const TestCards = lazy(
  () => import('../../components/pages/admin/testCards/TestCards')
);
const Webhooks = lazy(
  () => import('../../components/pages/admin/webhooks/Webhooks')
);
const AddWebhook = lazy(
  () => import('../../components/pages/admin/webhooks/AddWebhook')
);

const createLazyComponent = (Component, props = {}) => (
  <Suspense fallback={<Loader spinner />}>
    <Component {...props} />
  </Suspense>
);

const createRoute = (path, Component, props = {}) => ({
  path,
  element: createLazyComponent(Component, props),
});

export const adminRoutes = (abilities) => {
  const routes = [
    (abilities?.settings?.user?.read ?? false) &&
      createRoute(paths.users, Users),
    (abilities?.settings?.user?.write ?? false) &&
      createRoute(paths.newUser, AddUser),
    (abilities?.settings?.user?.write ?? false) &&
      createRoute(paths.editUser(), AddUser),
    (abilities?.settings?.logs?.read ?? false) && createRoute(paths.logs, Logs),
    (abilities?.settings?.plugins?.read ?? false) && {
      path: paths.plugins,
      element: (
        <Navigate
          replace={true}
          to={paths.smtp}
        />
      ),
    },
    (abilities?.settings?.smtp_account?.read ?? false) &&
      createRoute(paths.smtp, Smtps),
    (abilities?.settings?.fulfillment_house?.read ?? false) &&
      createRoute(paths.fulfilmentHouse, FulfilmentHouses),
    (abilities?.settings?.fulfillment_house?.edit ?? false) &&
      createRoute(paths.newFulfilmentHouse, AddFulfilmentHouse),
    (abilities?.settings?.fulfillment_house?.edit ?? false) &&
      createRoute(paths.editFulfilmentHouse(), AddFulfilmentHouse),
    (abilities?.settings?.affiliate?.read ?? false) && {
      path: paths.affiliates,
      element: (
        <Navigate
          replace={true}
          to={paths.adminAffiliates}
        />
      ),
    },
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.adminAffiliates, AdminAffiliates),
    (abilities?.settings?.affiliate?.edit ?? false) &&
      createRoute(paths.editAffiliate(), EditAffiliate),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.scrubSettings, ScrubSettings),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.prepaidBins, CardBins, { prepaid: true }),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.pixels(), Pixels),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.publishers(), Publishers),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.affiliateCampaigns(), AffiliateCampaigns),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.affiliateScrub(), AffiliateScrub),
    (abilities?.settings?.affiliate?.read ?? false) &&
      createRoute(paths.scrubSetting(), Scrubsetting),
    (abilities?.settings?.black_lists?.read ?? false) &&
      createRoute(paths.blacklists, Blacklists),
    (abilities?.settings?.white_lists?.read ?? false) &&
      createRoute(paths.whitelists, Whitelists),
    (abilities?.settings?.test_card?.read ?? false) &&
      createRoute(paths.testCards, TestCards),
    (abilities?.settings?.webhooks?.read ?? false) &&
      createRoute(paths.webhooks, Webhooks),
    (abilities?.settings?.webhooks?.write ?? false) &&
      createRoute(paths.newWebhook, AddWebhook),
    (abilities?.settings?.webhooks?.write ?? false) &&
      createRoute(paths.editWebhook(), AddWebhook),
  ].filter(Boolean);

  const indexPath = routes.length > 0 ? routes[0].path : '/';

  return {
    path: paths.getAdminLayout,
    children: [
      {
        index: true,
        element: (
          <Navigate
            replace={true}
            to={indexPath}
          />
        ),
      },
      ...routes,
    ],
  };
};
