import React from 'react';

const SelectTickIcon = ({ className, size = 'md' }) => {
  const sizes = {
    sm: { width: '12', height: '9' },
    md: { width: '18', height: '13' },
  };

  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 18 13'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M17 1L6 12L1 7'
        stroke='#0079FF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SelectTickIcon;
