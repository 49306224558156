import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  corporations: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getCorporations = createAsyncThunk(
  'corporations/getCorporations',
  async (params) => {
    const response = await handleAPI('/platform/corporations', 'get', params);
    return response;
  }
);

const corporationsSlice = createSlice({
  name: 'corporations',
  initialState,
  reducers: {
    editCorporation: (state, action) => {
      const corporations = [...state.corporations];
      const updatedRecord = action.payload;
      const index = corporations.findIndex(
        (corporation) => corporation.id === updatedRecord.id
      );
      if (index !== -1) {
        corporations[index] = updatedRecord;
      }
      state.corporations = corporations;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorporations.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCorporations.fulfilled, (state, action) => {
      state.isLoading = false;
      state.corporations = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getCorporations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const { editCorporation } = corporationsSlice.actions;

export default corporationsSlice.reducer;
