import React from 'react';

const XIcon = ({ className, size = 'md' }) => {
  const sizes = {
    xs: { width: '10', height: '10' },
    sm: { width: '12', height: '12' },
    md: { width: '14', height: '14' },
  };

  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M13 1L1 13M1 1L13 13'
        stroke='#495D80'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default XIcon;
