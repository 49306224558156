import React from 'react';

const ArrowLeftIcon = () => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.25 8.50012H1.25M1.25 8.50012L8.25 15.5M1.25 8.50012L8.25 1.5'
        stroke='#1E89FF'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowLeftIcon;
