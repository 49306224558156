import React from 'react';
import Button from '../../atoms/button/Button';
import UploadIcon from '../../icons/UploadIcon';
import { useMediaQuery } from '@mui/material';

const ImportButton = ({ onClickHandler, title = 'Import CSV', disabled }) => {
  const isMobile = useMediaQuery('(max-width: 812px)');

  const btnIcon = <UploadIcon />;

  return (
    <Button
      icon={btnIcon}
      title={!isMobile ? title : undefined}
      className={'py-3 md:py-2'}
      onClickHandler={onClickHandler}
      disabled={disabled}
    />
  );
};

export default ImportButton;
