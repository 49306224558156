import React, { useMemo, useState } from 'react';
import { StaticDatePicker, StaticTimePicker } from '@mui/x-date-pickers';
import { Tabs, Tab, Box, Button } from '@mui/material';
import CustomDay from '../DateTimeRangePicker/CustomDay';
import { setReferenceDate } from '../../../utils/helper/helper';
import moment from 'moment';
import { AccessTime, CalendarToday } from '@mui/icons-material';

const RangeCalendar = ({
  maxDate,
  minDate,
  onChange,
  value,
  showTime = true,
  startOrEnd = 'start',
  field,
  setToCustomRange,
}) => {
  const referenceDate = useMemo(
    () => setReferenceDate(startOrEnd),
    [startOrEnd]
  );

  const [currentTab, setCurrentTab] = useState('date');

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const getValue = () => {
    if (field) {
      return field.value;
    } else if (value) {
      return startOrEnd === 'start' ? value.startDate : value.endDate;
    } else {
      return null;
    }
  };

  const [selectedDateTime, setSelectedDateTime] = useState(getValue());

  const handleDateChange = (date) => {
    let newDateTime;
    if (selectedDateTime) {
      newDateTime = moment(selectedDateTime)
        .year(date.year())
        .month(date.month())
        .date(date.date());
    } else {
      newDateTime = date;
    }
    setSelectedDateTime(newDateTime);
    handleChange(date);
  };

  const handleTimeChange = (time) => {
    let newDateTime;
    if (selectedDateTime) {
      newDateTime = moment(selectedDateTime)
        .hour(time.hour())
        .minute(time.minute())
        .second(time.second());
    } else {
      newDateTime = moment()
        .hour(time.hour())
        .minute(time.minute())
        .second(time.second());
    }
    setSelectedDateTime(newDateTime);
    handleChange(newDateTime);
  };

  const handleChange = (dateTime) => {
    if (field) {
      field.onChange(dateTime);
      onChange && onChange();
    } else if (startOrEnd === 'start') {
      onChange({ ...value, startDate: dateTime });
    } else if (startOrEnd === 'end') {
      onChange({ ...value, endDate: dateTime });
    }
    setToCustomRange && setToCustomRange();
  };

  const handleClear = () => {
    setSelectedDateTime(null);
    handleChange(null);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'end',
      }}
      className={'bg-background'}
    >
      {currentTab === 'date' && (
        <StaticDatePicker
          maxDate={maxDate}
          minDate={minDate}
          referenceDate={referenceDate}
          onChange={handleDateChange}
          value={selectedDateTime}
          displayStaticWrapperAs='desktop'
          slots={{
            toolbar: null,
            shortcuts: null,
            day: CustomDay,
          }}
          slotProps={{
            layout: {
              sx: {
                boxShadow: 'none !important',
                borderRadius: '0 !important',
              },
              className: `${!showTime && '[&_.MuiTabs-root]:hidden'}`,
            },
            actionBar: { actions: [] },
            day: {
              startDate: value?.startDate ? moment(value.startDate) : null,
              endDate: value?.endDate ? moment(value.endDate) : null,
            },
            dayPicker: {
              sx: {
                '& .MuiDayCalendar-weekContainer': {
                  display: 'grid',
                  gridTemplateColumns: 'repeat(7, 1fr)',
                },
              },
            },
          }}
        />
      )}
      {currentTab === 'time' && (
        <StaticTimePicker
          onChange={handleTimeChange}
          value={selectedDateTime}
          ampmInClock
          referenceDate={referenceDate}
          slots={{
            toolbar: null,
            shortcuts: null,
          }}
          slotProps={{
            layout: {
              sx: {
                boxShadow: 'none !important',
                borderRadius: '0 !important',
              },
              className: `${!showTime && '[&_.MuiTabs-root]:hidden'}`,
            },
            actionBar: { actions: [] },
          }}
        />
      )}
      {showTime && (
        <Tabs
          variant={'fullWidth'}
          value={currentTab}
          onChange={handleTabChange}
        >
          <Tab
            value='date'
            icon={<CalendarToday />}
          />
          <Tab
            value='time'
            icon={<AccessTime />}
          />
        </Tabs>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 1,
        }}
      >
        <Button onClick={handleClear}>Clear</Button>
      </Box>
    </Box>
  );
};

export default RangeCalendar;
