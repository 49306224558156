import React from 'react';
import Input from '../../../atoms/input/Input';

const IntlBins = ({ register }) => {
  return (
    <>
      <Input
        label='Bin'
        register={register('bin')}
      />
      <Input
        label='Card Brand'
        register={register('card_brand')}
      />
      <Input
        label='Card Type'
        register={register('card_type')}
      />
      <Input
        label='Card Subtype'
        register={register('card_sub_type')}
      />
      <Input
        label='Issuing Organisation'
        register={register('issuing_organisation')}
      />
      <Input
        label='Country'
        register={register('country')}
      />
      <Input
        label='ISO2'
        register={register('iso2')}
      />
      <Input
        label='ISO3'
        register={register('iso3')}
      />
      <Input
        label='ISO Numeric'
        register={register('iso_numeric')}
      />
    </>
  );
};

export default IntlBins;
