import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  abilities: {},
  isLoading: true,
  error: null,
};

export const getAccessDetails = createAsyncThunk(
  'access/getAccessDetails',
  async (params) => {
    const response = await handleAPI('platform/abilities', 'get', params);
    return response;
  }
);

const accessDetailsSlice = createSlice({
  name: 'access details',
  initialState,
  reducers: {
    deleteUser: (state, action) => {
      state.users = state.users.filter((user) => user.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessDetails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAccessDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getAccessDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.abilities = action.payload.data;
    });
  },
});

export default accessDetailsSlice.reducer;
