import React, { Suspense, lazy } from 'react';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';

const Campaigns = lazy(
  () => import('../../components/pages/campaign/campaigns/Campaigns')
);
const CampaignSettings = lazy(
  () =>
    import('../../components/pages/campaign/campaignSettings/CampaignSettings')
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

const createRoute = (path, Component) => ({
  path,
  element: createLazyComponent(Component),
});

export const campaignRoutes = (abilities) => {
  return [
    (abilities?.campaign?.read ?? false) &&
      createRoute(paths.campaigns, Campaigns),
    (abilities?.category?.read ?? false) &&
      createRoute(paths.campaignSettings, CampaignSettings),
  ];
};
