import React from 'react';
import { Outlet } from 'react-router-dom';
import PageTitle from '../atoms/pageTitle/PageTitle';
import LayoutNav from '../molecule/LayoutNav/LayoutNav';

const LayoutWithNav = ({ navItems, pageTitle, skipBorder = false }) => {
  return (
    <div className='grid grid-cols-1 grid-rows-[max-content_max-content_1fr] place-content-start gap-6 md:grid-cols-[max-content_1fr] md:grid-rows-[max-content_max-content] md:[&>*:first-child]:col-span-2'>
      <PageTitle title={pageTitle} />
      <LayoutNav items={navItems} />

      <div
        className={` ${!skipBorder ? 'border-gray-line md:rounded md:border md:p-6' : ''} flex h-full flex-grow flex-col self-stretch md:bg-background-form`}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default LayoutWithNav;
