import React, { useRef, useState } from 'react';
import { StaticDateTimePicker } from '@mui/x-date-pickers';
import DatePickerButton from '../../atoms/DatePickerButton/DatePickerButton';
import FilterModal from '../modal/FilterModal/FilterModal';
import { useId } from 'react';
import CustomDay from '../DateTimeRangePicker/CustomDay';
import { setReferenceDate } from '../../../utils/helper/helper';

const DatePicker = ({
  value,
  onChange,
  minDate,
  maxDate,
  showTime = false,
  buttonClasses,
  startEndOfDay,
  startIcon = false,
  endIcon = false,
  field,
  onSubmit,
  onCancel,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const id = useId();

  const ref = useRef(null);

  const handleButtonClick = (mobile) => {
    setIsMobile(mobile);
  };

  /*
  Sets the reference date and time, so you dont have to manually select the time, after you select the date
   */
  const referenceDate = setReferenceDate(startEndOfDay);

  const calendar = (
    <StaticDateTimePicker
      maxDate={maxDate}
      minDate={minDate}
      onChange={(e) => {
        onChange?.(e);
        field?.onChange?.(e);
      }}
      value={field?.value ?? value ?? null}
      onBlur={field?.onBlur}
      ampmInClock
      referenceDate={referenceDate}
      views={
        showTime
          ? ['year', 'month', 'day', 'hours', 'minutes']
          : ['year', 'month', 'day']
      }
      slots={{
        toolbar: null,
        shortcuts: null,
        day: CustomDay,
      }}
      slotProps={{
        layout: {
          className: `${!showTime && '[&_.MuiTabs-root]:hidden'}`,
        },
        actionBar: { actions: !isMobile ? ['clear'] : [] },
        day: {
          selectedDate: field?.value ?? value ?? null,
        },
      }}
    />
  );

  return (
    <div className='!z-100 dropdown-end dropdown'>
      <div
        tabIndex={0}
        role='button'
      >
        <DatePickerButton
          className={`flex lg:hidden ${buttonClasses}`}
          placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
          isTime={showTime}
          value={field?.value ?? value ?? null}
          onBlur={field?.onBlur}
          onClick={() => handleButtonClick(true)}
          startIcon={startIcon}
          endIcon={endIcon}
        />
        <DatePickerButton
          className={`hidden lg:flex ${buttonClasses}`}
          placeholder={showTime ? 'mm/dd/yyy hh:mm' : 'mm/dd/yyyy'}
          isTime={showTime}
          value={field?.value ?? value ?? null}
          onBlur={field?.onBlur}
          onClick={() => handleButtonClick(false)}
          startIcon={startIcon}
          endIcon={endIcon}
        />
      </div>

      {isMobile ? (
        <FilterModal
          id={id}
          title='Select Date'
          submitButtonTitle='Apply'
          cancelButtonTitle='Clear'
          openOnLoad
          onSubmit={onSubmit}
          onCancel={(e) => {
            onCancel?.(e);
            onChange?.(null);
            field?.onChange?.(null);
          }}
          onClose={() => setIsMobile(false)}
        >
          {calendar}
        </FilterModal>
      ) : (
        <div
          ref={ref}
          tabIndex={0}
          onClick={() => ref.current.focus()}
          className='dropdown-content !z-[100] hidden lg:flex'
        >
          {calendar}
        </div>
      )}
    </div>
  );
};

export default DatePicker;
