import React from 'react';

const UploadIcon = ({ className, size = 'md' }) => {
  const sizes = {
    sm: { width: '12', height: '12' },
    md: { width: '16', height: '16' },
  };

  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M19 13V17C19 17.5304 18.7893 18.0391 18.4142 18.4142C18.0391 18.7893 17.5304 19 17 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V13M15 6L10 1M10 1L5 6M10 1V13'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default UploadIcon;
