import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  heading: '',
  description: '',
  closeButtonText: 'Cancel',
  actionButtonText: 'Delete',
  action: () => null,
  closeAction: () => null,
  cancelAction: () => null,
};

const confirmationModalSlice = createSlice({
  name: 'confirmationModal',
  initialState,
  reducers: {
    setModalContent: (state, action) => {
      return {
        ...state,
        heading: action.payload.heading,
        description: action.payload.description,
        closeButtonText: action.payload.closeButtonText ?? 'Cancel',
        actionButtonText: action.payload.actionButtonText ?? 'Delete',
        action: action.payload.action,
        closeAction: action.payload.closeAction,
        cancelAction: action.payload.cancelAction,
      };
    },
    resetModalContent: () => initialState,
  },
});

export const { setModalContent, resetModalContent } =
  confirmationModalSlice.actions;

export const openActionModal = (payload) => (dispatch) => {
  dispatch(setModalContent(payload));
  window.deleteModal.showModal();
};

export const closeActionModal = () => (dispatch) => {
  window.deleteModal.close();
  setTimeout(() => {
    dispatch(resetModalContent());
  }, 150);
};

export default confirmationModalSlice.reducer;
