import React, { useEffect, useRef, useState } from 'react';
import Input from '../input/Input';
import DownCaretIcon from '../../icons/DownCaretIcon';

const Select = ({
  options,
  label,
  selectedOptions,
  setSelectedOptions,
  isSelectAll = false,
  isSearch = true,
  position,
  className,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef(null);

  const handleOptionToggle = (option) => {
    if (selectedOptions === options) {
      setSelectedOptions({});
    } else if (option === '[Select All]') {
      setSelectedOptions(options);
    } else {
      setSelectedOptions(option);
    }
    setOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const filteredOptions = options.filter((option) =>
    option.key.toLowerCase().includes(search.toLowerCase())
  );

  const renderOptions = () => {
    return (
      <div
        className={`absolute border border-gray-line ${
          position === 'top' && 'bottom-0'
        } background z-50 w-full rounded-[5px]`}
      >
        <div className='mx-2'>
          {isSearch && (
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className='mt-2 w-full'
            />
          )}
          {isSelectAll && (
            <div
              className={`cursor pointer py-2 ${
                selectedOptions.key === options.key ? 'text-primary' : ''
              }`}
              onClick={() => handleOptionToggle('[Select All]')}
            >
              {`All ` + (label ?? '')}
            </div>
          )}
          {filteredOptions.map((option) => (
            <div
              key={option.key}
              className={`cursor-pointer py-2 ${
                selectedOptions.key === option.key ? 'text-primary' : ''
              }`}
              onClick={() => handleOptionToggle(option)}
            >
              {option.key}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={className}>
      <label className='text-color text-small capitalize'>{label}</label>
      <div
        className='multiselect relative'
        ref={dropdownRef}
      >
        <button
          onClick={() => setOpen((prev) => !prev)}
          className='flex cursor-pointer items-center gap-3 rounded-[5px] border border-gray-line px-3 py-[6px] text-sm text-text'
        >
          <p>
            {selectedOptions?.length === options?.length
              ? `All ` + label
              : selectedOptions.key}
          </p>
          <DownCaretIcon
            size='sm'
            pathClassName='fill-text'
          />
        </button>

        {open && renderOptions()}
      </div>
    </div>
  );
};

export default Select;
