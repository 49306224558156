import React from 'react';

const EndIcon = ({ className }) => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g transform='scale(-1,1) translate(-24,0)'>
        <path
          className={className}
          d='M14.59 7.41L18.17 11H6v2h12.17l-3.59 3.59L16 18l6-6-6-6z M2 6v12h2V6z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default EndIcon;
