import React, { useEffect, useState } from 'react';
import TabGroup from '../../molecule/TabGroup/TabGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import VerticalTabGroup from '../../molecule/VerticalTabGroup/VerticalTabGroup';

const NavigableTabGroup = ({ tabs, verticalMenu = false }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentTab = tabs.find((tab) => location.pathname === tab.path);
    if (currentTab) {
      setActiveTab(currentTab);
    }
  }, [location, tabs]);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
    navigate(tab.path);
  };

  return (
    <>
      {verticalMenu ? (
        <VerticalTabGroup
          tabs={tabs}
          activeTab={activeTab}
          onTabSelect={handleTabSelect}
        />
      ) : (
        <TabGroup
          tabs={tabs}
          activeTab={activeTab}
          onTabSelect={handleTabSelect}
        />
      )}
    </>
  );
};

export default NavigableTabGroup;
