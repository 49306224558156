import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  blacklists: [],
  totalPages: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getBlacklists = createAsyncThunk(
  'blacklist/getBlacklists',
  async (params) => {
    const response = await handleAPI('platform/black_lists', 'get', params);
    return response;
  }
);

const blacklistSlice = createSlice({
  name: 'blacklist',
  initialState,
  reducers: {
    deleteBlackList: (state, action) => {
      state.blacklists = state.blacklists.filter(
        (blacklist) => blacklist.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBlacklists.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBlacklists.fulfilled, (state, action) => {
      state.isLoading = false;
      state.blacklists = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getBlacklists.rejected, (state, action) => {
      state.isLoading = false;
      state.totalPages = 0;
      state.totalCount = 0;
      state.count = 0;
      state.error = action.payload;
    });
  },
});

export const { deleteBlackList } = blacklistSlice.actions;

export default blacklistSlice.reducer;
