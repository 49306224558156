import React, { useId } from 'react';
import PropTypes from 'prop-types';
import Label from '../Label/Label';

const Input = ({
  type = 'text',
  placeholder = 'Type here',
  label,
  className = '',
  labelClassName = '',
  wrapperClassName = '',
  register,
  infoData,
  required = false,
  isError = false,
  isDisabled = false,
  error,
  icon,
  labelHidden = false,
  onChange,
  onBlur,
  ...props
}) => {
  const id = useId();
  return (
    <div className={`w-full ${wrapperClassName}`}>
      {label && (
        <Label
          id={id}
          label={label}
          required={required}
          infoData={infoData}
          className={`${labelClassName} ${isDisabled && '!text-gray-1'}`}
          labelHidden={labelHidden}
        />
      )}
      <div className='relative flex items-center'>
        <input
          id={id}
          type={type}
          placeholder={placeholder}
          className={`w-full rounded-[5px] border border-gray-5 bg-transparent px-3 py-[0.5625rem] text-sm text-text outline-none placeholder:text-gray-5 focus:border-blue ${className} ${(isError || error) && '!border-error'} ${icon && 'pr-14'} ${isDisabled && '!border-gray-2 !text-gray-1'} `}
          disabled={isDisabled}
          {...register}
          {...props}
          onChange={(e) => {
            register?.onChange?.(e);
            onChange?.(e);
          }}
          onBlur={(e) => {
            register?.onBlur?.(e);
            onBlur?.(e);
          }}
          autoComplete='off'
        />
        <div className='absolute right-5'>{icon}</div>
      </div>
      {error && <p className='text-xs text-error'>{error}</p>}
    </div>
  );
};

Input.propTypes = {
  type: PropTypes.oneOf(['text', 'number', 'tel', 'password', 'email', 'date']),
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  labelHidden: PropTypes.bool,
};

export default Input;
