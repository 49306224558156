import React, { useCallback, useEffect, useRef, useState } from 'react';
import CloseButton from '../../atoms/CloseButton/CloseButton';
import ModalTitle from '../../atoms/modalTitle/ModalTitle';
import Button from '../../atoms/button/Button';
import SpinnerIcon from '../../icons/SpinnerIcon';
import lodash from 'lodash';

const Modal = ({
  id,
  children,
  className,
  onSubmit,
  onCancel,
  onClose,
  submitButtonDisabled,
  cancelButtonTitle = 'Cancel',
  submitButtonTitle = 'Save',
  title,
  openOnLoad,
  hideButtons = false,
  isLoading = false,
  setIsModalOpen,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleCancel = () => {
    onCancel?.();
    handleClose();
  };

  const handleClose = useCallback(() => {
    try {
      onClose?.();
    } catch (e) {
      // Do nothing
    }
    ref.current?.close();
  }, [onClose]);

  useEffect(() => {
    const handler = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    document.addEventListener('keydown', handler);
    return () => {
      document.removeEventListener('keydown', handler);
    };
  }, [handleClose]);

  const generateId = useCallback((element) => `${id}-${element}`, [id]);

  useEffect(() => {
    if (openOnLoad) ref.current.showModal();
  }, [openOnLoad]);

  useEffect(() => {
    if (hideButtons) {
      const dialogRef = ref.current;

      const clickHandler = (e) => {
        if (
          !document.getElementById(generateId('wrapper')).contains(e.target)
        ) {
          handleClose();
        }
      };

      dialogRef.addEventListener('click', clickHandler);

      return () => {
        dialogRef.removeEventListener('click', clickHandler);
      };
    }
  }, [generateId, handleClose, hideButtons]);

  useEffect(() => {
    if (ref.current) {
      const mutationObserver = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.attributeName === 'open') {
            setIsOpen(ref.current.open);
            setIsModalOpen?.(ref.current.open);
          }
        });
      });

      mutationObserver.observe(ref.current, { attributes: true });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [setIsModalOpen]);

  useEffect(() => {
    if (isOpen) {
      const modalContent = document.getElementById(generateId('content'));

      const updateShadow = lodash.debounce(() => {
        const modalHeader = document.getElementById(generateId('header'));
        const modalFooter = document.getElementById(generateId('footer'));

        modalHeader?.classList.remove('shadow-bottom');
        modalFooter?.classList.remove('shadow-top');

        if (modalContent.scrollHeight > modalContent.clientHeight) {
          if (modalContent?.scrollTop === 0) {
            // Scroll at the top
            modalFooter?.classList.add('shadow-top');
          } else if (
            modalContent?.scrollTop + modalContent?.clientHeight >=
            modalContent?.scrollHeight
          ) {
            // Scroll at the bottom
            modalHeader?.classList.add('shadow-bottom');
          } else {
            // Scroll somewhere in the middle
            modalFooter?.classList.add('shadow-top');
            modalHeader?.classList.add('shadow-bottom');
          }
        }
      }, 50);

      modalContent.addEventListener('scroll', updateShadow);
      window.addEventListener('resize', updateShadow);
      if (isOpen) updateShadow();

      return () => {
        modalContent.removeEventListener('scroll', updateShadow);
        window.removeEventListener('resize', updateShadow);
      };
    }
  }, [generateId, hideButtons, id, isOpen]);

  return (
    <dialog
      id={id}
      ref={ref}
      className='modal modal-top z-50 flex items-center justify-center p-4'
    >
      {isOpen && (
        <div
          className={`relative mx-auto my-4 w-full max-w-[700px] rounded-lg bg-background shadow-lg ${className}`}
          id={generateId('wrapper')}
        >
          <div
            className='border-b-1 relative grid border border-transparent p-6 transition'
            id={generateId('header')}
          >
            <CloseButton
              onClick={handleClose}
              className='absolute right-7 top-7'
            />

            <ModalTitle
              title={title}
              className='mt-6'
            />
          </div>

          <div
            className={`overflow-auto px-6 pb-6 ${hideButtons ? 'max-h-[calc(100vh-12rem)]' : 'max-h-[calc(100vh-18rem)]'} `}
            id={generateId('content')}
          >
            {children}
          </div>

          {!hideButtons && (
            <div
              className='border-t-1 relative flex gap-4 border border-transparent p-6 transition [&>*]:flex-grow'
              id={generateId('footer')}
            >
              <Button
                title={cancelButtonTitle}
                variant='ghost'
                onClickHandler={handleCancel}
              />

              <Button
                title={submitButtonTitle}
                onClickHandler={onSubmit}
                disabled={submitButtonDisabled}
                icon={
                  isLoading ? (
                    <SpinnerIcon svgClassname={'animate-spin'} />
                  ) : undefined
                }
              />
            </div>
          )}
        </div>
      )}
    </dialog>
  );
};

export default Modal;
