import React, { useState } from 'react';
import Button from '../../atoms/button/Button';
import FilterButton from '../../atoms/FilterButton/FilterButton';
import FilterModal from '../modal/FilterModal/FilterModal';

const Filterbar = ({
  onSubmit,
  onCancel,
  children,
  applyDisabled,
  clearDisabled,
  onlyModal,
  className,
  hideApply,
  hideClear,
}) => {
  const [showMobileModal, setShowMobileModal] = useState(false);

  const handleOpenModal = () => {
    setShowMobileModal(true);
  };

  const handleSubmit = (data) => {
    onSubmit(data);
    handleClose();
  };

  const handleCancel = () => {
    onCancel();
    handleClose();
  };

  const handleClose = () => {
    setShowMobileModal(false);
  };

  return (
    <>
      {!onlyModal && (
        <form
          onSubmit={onSubmit}
          className={`hidden items-end gap-4 md:flex ${onlyModal && '!hidden'} ${className ? className : ''}`}
        >
          {children}

          {!hideApply && (
            <Button
              title='Apply'
              type='submit'
              disabled={applyDisabled}
            />
          )}

          {!hideClear && (
            <Button
              title='Clear'
              type='button'
              variant='ghost'
              onClickHandler={handleCancel}
              disabled={clearDisabled}
            />
          )}
        </form>
      )}

      <div
        className={`flex items-center justify-end gap-4 md:hidden ${onlyModal && '!flex'}`}
      >
        <FilterButton onClick={handleOpenModal} />

        {showMobileModal && (
          <FilterModal
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onClose={handleClose}
            submitButtonDisabled={applyDisabled}
            openOnLoad
          >
            {children}
          </FilterModal>
        )}
      </div>
    </>
  );
};

export default Filterbar;
