import React from 'react';

const TableRow = ({ children, className }) => {
  return (
    <tr
      className={`border-b border-gray-line bg-background hover:bg-background-subheader [&>td:first-child]:px-3 [&>td:last-child]:px-3 ${className}`}
    >
      {children}
    </tr>
  );
};

export default TableRow;
