import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideToastr } from '../../../redux/slices/toastrSlice';
import ToastrCheckIcon from '../../icons/ToastrCheckIcon';
import { ToastrXIcon } from 'components/icons/ToastrXIcon';

const Toastr = () => {
  const dispatch = useDispatch();
  const toastrList = useSelector((state) => state.toastr.toastrList);
  const [hoveredToastr, setHoveredToastr] = useState(undefined);

  useEffect(() => {
    toastrList.forEach((_, index) => {
      const timer = setTimeout(
        () => {
          if (index !== hoveredToastr) {
            dispatch(hideToastr(index));
          }
        },
        3500 * (index + 1)
      );
      return () => clearTimeout(timer);
    });
  }, [toastrList, dispatch, hoveredToastr]);

  const getColors = (type) => {
    return type === 'error'
      ? 'bg-state-red-background text-state-red [&_path]:fill-state-red before:bg-state-red'
      : 'bg-state-green-background text-state-green [&_path]:fill-state-green before:bg-state-green';
  };

  const handleMouseEnter = (index) => {
    setHoveredToastr(index);
  };

  const handleMouseLeave = () => {
    setHoveredToastr(null);
  };

  return (
    <div className='fixed top-3 !z-[1000] flex w-screen flex-col items-center gap-2 md:top-20'>
      {toastrList.map((toastr, index) => (
        <div
          key={index}
          className={`relative mx-4 flex max-w-[600px] items-center justify-between gap-2 rounded-l-[4px] rounded-r-md p-3 text-sm font-semibold before:absolute before:right-0 before:top-0 before:-z-[1] before:h-full before:w-[calc(100%+5px)] before:rounded-lg before:content-[''] ${getColors(toastr.type)} `}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
        >
          {toastr.type === 'error' ? (
            <ToastrXIcon svgClassName='min-w-max min-h-max' />
          ) : (
            <ToastrCheckIcon svgClassName='min-w-max min-h-max' />
          )}
          <p>{toastr.message}</p>
        </div>
      ))}
    </div>
  );
};

export default Toastr;
