import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  publishers: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getPublishers = createAsyncThunk(
  'publishers/getPublishers',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/affiliates/${id}/publishers`,
      'get',
      params
    );
    return response;
  }
);

const publisherSlice = createSlice({
  name: 'publisher',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPublishers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPublishers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.publishers = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getPublishers.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default publisherSlice.reducer;
