import React from 'react';
import { Link } from 'react-router-dom';

const LayoutNavItem = ({ title, isActive, href }) => {
  return (
    <Link
      className={`rounded-md border border-transparent px-3 py-[5px] text-sm text-gray-1 hover:bg-background-header ${
        isActive
          ? 'border-gray-2 !bg-background text-primary shadow-[0px_1px_2px_0px_#0000000D]'
          : ''
      }`}
      to={href}
    >
      {title}
    </Link>
  );
};

export default LayoutNavItem;
