import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  smtps: [],
  smtp: {},
  meta: {},
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getSmtps = createAsyncThunk('smtp/getSmtps', async (params) => {
  const response = await handleAPI(
    '/platform/settings/smtp_accounts',
    'get',
    params
  );
  return response;
});

export const getSmtp = createAsyncThunk(
  'smtp/getSmtp',
  async ({ id, params }) => {
    const response = await handleAPI(
      `/platform/settings/smtp_accounts/${id}`,
      'get',
      params
    );
    return response;
  }
);

const smtpSlice = createSlice({
  name: 'smtp',
  initialState,
  reducers: {
    deleteSmtp: (state, action) => {
      state.smtps = state.smtps.filter((smtp) => smtp.id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSmtps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSmtps.fulfilled, (state, action) => {
      state.isLoading = false;
      state.smtps = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
      state.meta = action.metaData;
    });
    builder.addCase(getSmtps.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getSmtp.fulfilled, (state, action) => {
      state.isLoading = false;
      state.smtp = action.payload;
    });
  },
});
export const { deleteSmtp } = smtpSlice.actions;

export default smtpSlice.reducer;
