import React from 'react';
import XIcon from '../../icons/XIcon';
import PropTypes from 'prop-types';

const CloseButton = ({ onClick, className, type = 'button' }) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={`${className} transition-all hover:brightness-75`}
    >
      <XIcon className='stroke-gray-5' />
    </button>
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default CloseButton;
