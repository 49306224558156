import React, { useMemo } from 'react';
import FilterBadge from '../../molecule/FilterBadge/FilterBadge';
import { timeFormatter } from 'utils/helper/helper';
import moment from 'moment/moment';

const FilterBadgeList = ({ filters, onClear, onClearAll }) => {
  const entries = useMemo(
    () => Object.entries(filters)?.filter(([, value]) => !!value),
    [filters]
  );

  const shouldShow = useMemo(() => !!entries.length, [entries.length]);

  const getBadgeValue = (value) => {
    const valueType = typeof value;

    if (valueType === 'string' || valueType === 'number') {
      return value;
    }

    if (valueType === 'object') {
      if (Array.isArray(value)) {
        return value.map((v) => v.name ?? v.type).join(', ');
      }

      if (moment.isMoment(value)) {
        return timeFormatter(value.toDate());
      }

      if (value instanceof Date) {
        return timeFormatter(value);
      }

      return value.name;
    }

    return undefined;
  };

  return (
    <>
      {shouldShow && (
        <div className='flex w-full flex-wrap gap-2'>
          {entries.map(([key, value], index) => (
            <FilterBadge
              key={key}
              color={index % 2 === 0 ? 'blue' : 'green'}
              filterKey={key}
              filterValue={getBadgeValue(value)}
              onClose={onClear}
            />
          ))}

          <button
            onClick={onClearAll}
            className='rounded-full border border-background-header px-3 py-[2px] text-sm text-primary'
          >
            Clear all
          </button>
        </div>
      )}
    </>
  );
};

export default FilterBadgeList;
