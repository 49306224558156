import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';
import { deserializeData } from '../../../utils/helper/helper';

const initialState = {
  products: [],
  product: {},
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

const getVariantOptionValues = async (id) => {
  const response = await handleAPI(`platform/variants/${id}`, 'get', {
    include: 'option_values',
  });
  const data = await deserializeData(response.data);
  return data.option_values;
};

export const getProducts = createAsyncThunk(
  'products/getProducts',
  async (params) => {
    const response = await handleAPI('/platform/products', 'get', params);
    return response;
  }
);

export const getProduct = createAsyncThunk(
  'products/getProduct',
  async ({ id, params }) => {
    const response = await handleAPI(`/platform/products/${id}`, 'get', params);

    for (let i = 0; i < response?.data?.included?.length; i++) {
      delete response?.data?.included[i].relationships;
    }

    const product = await deserializeData(response.data);

    if (!product?.variants_including_master?.length) {
      return response;
    }

    const variantsWithOptionValues = await Promise.all(
      product?.variants_including_master?.map(async (variant) => {
        const optionValuesData = await getVariantOptionValues(variant.id);
        return {
          ...variant,
          option_values: optionValuesData,
        };
      })
    );

    return {
      ...product,
      variants_including_master: variantsWithOptionValues,
    };
  }
);

const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    deleteProduct: (state, action) => {
      state.products = state.products.filter(
        (product) => product.id !== action.payload
      );
    },
    updateProduct: (state, action) => {
      return {
        ...state,
        product: { ...state.product, ...action.payload },
      };
    },
    addProductVariant: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          variants_including_master: [
            action.payload,
            ...state.product.variants_including_master,
          ],
        },
      };
    },
    updateProductVariant: (state, action) => {
      return {
        ...state,
        product: {
          ...state.product,
          variants_including_master:
            state.product.variants_including_master.map((variant) =>
              variant.id === action.payload.id ? action.payload : variant
            ),
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProducts.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getProducts.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        products: action.payload,
        totalPages: action?.metaData?.total_pages,
        totalCount: action?.metaData?.total_count,
      };
    });
    builder.addCase(getProducts.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    });
    builder.addCase(getProduct.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getProduct.fulfilled, (state, action) => {
      return {
        ...state,
        product: action.payload,
        isLoading: false,
      };
    });
  },
});
export const {
  deleteProduct,
  updateProduct,
  addProductVariant,
  updateProductVariant,
} = productSlice.actions;

export default productSlice.reducer;
