import React, { useEffect } from 'react';
import { useState } from 'react';
import useCommanForm from '../../../hooks/form/useCommanForm';
import Button from '../../atoms/button/Button';
import DatePicker from '../DateRangePicker/DatePicker';
import FilterBadgeList from '../../organism/FilterBadgeList/FilterBadgeList';
import Filterbar from '../Filterbar/Filterbar';
import { Controller } from 'react-hook-form';
import { DateRangeOptions } from 'constants/options/dateRangeOptions';
import { useMediaQuery } from '@mui/material';
import MultiSelect from 'components/atoms/multiSelect/MultiSelect';
import * as yup from 'yup';
import DateTimeRangePicker from '../DateTimeRangePicker/DateTimeRangePicker';

const schema = yup.object();

const FilterBarWrapper = ({
  FilterForm,
  onSubmit,
  dateRange,
  setDateRange,
  showDateInputs = true,
}) => {
  const defaultValues = {
    startDate: dateRange?.start ?? null,
    endDate: dateRange?.end ?? null,
  };

  const {
    handleSubmit,
    register,
    reset,
    control,
    getValues,
    resetField,
    setValue,
    watch,
  } = useCommanForm(schema, defaultValues);

  const isTablet = useMediaQuery('(max-width: 1280px)');
  const showDateRange = dateRange && setDateRange;

  const startDateWatch = watch('startDate');
  const endDateWatch = watch('endDate');

  useEffect(() => {
    if (showDateRange && (!startDateWatch || !endDateWatch)) {
      setDateRange(DateRangeOptions.CUSTOM);
    }
  }, [endDateWatch, setDateRange, showDateRange, startDateWatch]);

  const [appliedFilters, setAppliedFilters] = useState({
    startDate: defaultValues.startDate,
    endDate: defaultValues.endDate,
  });

  const handleFormSubmit = (data) => {
    onSubmit(data);
    setAppliedFilters(getValues());
  };

  const handleCancel = () => {
    reset({
      startDate: null,
      endDate: null,
    });

    handleSubmit(handleFormSubmit)();
  };

  const handleResetIndividualField = (key) => {
    resetField(key, { defaultValue: null });
    handleSubmit(handleFormSubmit)();
  };

  const setToCustomRange = () => {
    showDateRange && setDateRange(DateRangeOptions.CUSTOM);
  };

  return (
    <>
      <Filterbar
        onSubmit={handleSubmit(handleFormSubmit)}
        onCancel={handleCancel}
        onlyModal
      >
        <FilterForm
          register={register}
          control={control}
        />
      </Filterbar>

      {showDateInputs && (
        <>
          <div className='flex w-full flex-col gap-3 lg:w-max lg:flex-row [&>*]:w-full lg:[&>*]:w-auto [&>div>div>button]:w-full'>
            {showDateRange && (
              <MultiSelect
                className='lg:w-52'
                options={DateRangeOptions}
                sortOptions={false}
                placeholder='Date Range'
                selectedOptions={dateRange}
                setSelectedOptions={setDateRange}
                isClearable={false}
                onChange={(v) => {
                  setValue('startDate', v.start);
                  setValue('endDate', v.end);

                  if (isTablet) {
                    handleSubmit(handleFormSubmit)();
                  }
                }}
              />
            )}

            {isTablet ? (
              <>
                <Controller
                  name='startDate'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'start'}
                      startIcon
                      onChange={setToCustomRange}
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() => handleResetIndividualField('startDate')}
                      showTime
                    />
                  )}
                />

                <Controller
                  name='endDate'
                  control={control}
                  render={({ field }) => (
                    <DatePicker
                      field={field}
                      startEndOfDay={'end'}
                      endIcon
                      onChange={setToCustomRange}
                      onSubmit={handleSubmit(handleFormSubmit)}
                      onCancel={() => handleResetIndividualField('endDate')}
                      showTime
                    />
                  )}
                />
              </>
            ) : (
              <DateTimeRangePicker
                control={control}
                watch={watch}
                setToCustomRange={setToCustomRange}
              />
            )}
          </div>

          <Button
            title='Apply'
            type='submit'
            className='hidden lg:flex'
            onClickHandler={handleSubmit(handleFormSubmit)}
          />
        </>
      )}

      <FilterBadgeList
        filters={appliedFilters}
        onClear={handleResetIndividualField}
        onClearAll={handleCancel}
      />
    </>
  );
};

export default FilterBarWrapper;
