import React from 'react';

const PaginationDoubleCaret = ({ className }) => {
  return (
    <svg
      className={className}
      width='10'
      height='8'
      viewBox='0 0 10 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.94 0L0 0.94L3.05333 4L0 7.06L0.94 8L4.94 4L0.94 0Z'
        fill='#242546'
      />
      <path
        d='M5.94 0L5 0.94L8.05333 4L5 7.06L5.94 8L9.94 4L5.94 0Z'
        fill='#242546'
      />
    </svg>
  );
};

export default PaginationDoubleCaret;
