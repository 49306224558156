const paymentPaths = {
  gateways: '/payment/gateways',
  newGateway: '/payment/gateways/new',
  editGateway: (id = ':id') => `/payment/gateways/${id}/edit`,
  corporations: '/payment/corporations',
  banks: '/payment/banks',
  pools: '/payment/pools',
  newPool: '/payment/pools/new',
  editPool: (id = ':id') => `/payment/pools/${id}/edit`,
  rebillPools: '/payment/rebill-pools',
  newRebillPool: '/payment/rebill-pools/new',
  editRebillPool: (id = ':id') => `/payment/rebill-pools/${id}/edit`,
  binRoutings: '/payment/bin-routings',
  cardBins: '/payment/bin-routings/card-bins',
  newBinRoutings: '/payment/bin-routings/new',
  editBinRoutings: (id = ':id') => `/payment/bin-routings/${id}/edit`,
  settings: '/payment/settings',
  retries: '/payment/settings/retries',
  reprocflowMapping: '/payment/settings/reproc-flow-mapping',
  rebillflowMapping: '/payment/settings/rebill-flow-mapping',
  gatewayProfiles: '/payment/gateway-profiles',
  newGatewayProfile: '/payment/gateway-profiles/new',
  editGatewayProfile: (id = ':id') => `/payment/gateway-profiles/${id}/edit`,
};

export default paymentPaths;
