import axios from 'axios';
import qs from 'qs';
import { BACKEND_URL } from '../../env';
import { getCookie } from '../helper/helper';

export const handleAPI = (
  path,
  method,
  params,
  data = null,
  responseType = 'json'
) => {
  const headers = {
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    ...(getCookie('token') && { Authorization: getCookie('token') }),
  };

  let url = BACKEND_URL + path;
  if (params) {
    url = url + '?' + qs.stringify(params, { arrayFormat: 'brackets' });
  }
  return axios({
    method,
    url,
    ...(data && { data }),
    responseType,
    headers,
  });
};
