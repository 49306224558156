import React, { forwardRef } from 'react';
import FilterIcon from '../../icons/FilterIcon';
import Button from '../button/Button';

const FilterButton = forwardRef(({ onClick }, ref) => {
  return (
    <Button
      ref={ref}
      icon={<FilterIcon />}
      variant='ghost'
      className='!px-[20px] !py-[9px]'
      onClickHandler={onClick}
    />
  );
});

FilterButton.displayName = 'FilterButton';

export default FilterButton;
