import React, { useEffect, useRef } from 'react';

const TabGroup = ({ tabs, onTabSelect, activeTab }) => {
  const groupRef = useRef(null);

  const getTabId = (tab) => `tab-${tab.value}`;

  useEffect(() => {
    const tabElement = groupRef.current.querySelector(
      `#${getTabId(activeTab)}`
    );
    groupRef.current.style.setProperty(
      '--tab-width',
      `${tabElement.offsetWidth}px`
    );
    groupRef.current.style.setProperty(
      '--tab-left',
      `${tabElement.offsetLeft}px`
    );
  }, [activeTab]);

  return (
    <div
      ref={groupRef}
      className='relative flex w-max gap-5 border-b border-gray-2 before:absolute before:bottom-0 before:left-[var(--tab-left)] before:w-[var(--tab-width)] before:border-b-2 before:border-primary before:transition-all before:content-[""]'
    >
      {tabs.map((tab, index) => (
        <button
          id={getTabId(tab)}
          className={`px-1 py-2 text-sm text-gray-1 ${tab.value === activeTab.value && 'text-primary'} `}
          key={index}
          onClick={() => onTabSelect(tab)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default TabGroup;
