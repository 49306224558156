import React from 'react';
import PropTypes from 'prop-types';

const DownCaretIcon = ({ svgClassName, pathClassName, size = 'md' }) => {
  const sizes = {
    sm: { width: '8', height: '6' },
    md: { width: '12', height: '8' },
    lg: { width: '13', height: '9' },
  };

  return (
    <svg
      className={svgClassName}
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 8 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={pathClassName}
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.234473 0.834441C0.384496 0.684464 0.587942 0.600211 0.800073 0.600211C1.0122 0.600211 1.21565 0.684464 1.36567 0.834441L4.00007 3.46884L6.63447 0.834441C6.70827 0.758033 6.79655 0.697087 6.89415 0.655159C6.99175 0.613232 7.09673 0.591163 7.20295 0.59024C7.30918 0.589317 7.41452 0.609558 7.51284 0.649783C7.61115 0.690008 7.70047 0.74941 7.77559 0.824524C7.8507 0.899639 7.91011 0.98896 7.95033 1.08728C7.99056 1.18559 8.0108 1.29094 8.00987 1.39716C8.00895 1.50339 7.98688 1.60836 7.94495 1.70596C7.90303 1.80357 7.84208 1.89184 7.76567 1.96564L4.56567 5.16564C4.41565 5.31562 4.2122 5.39987 4.00007 5.39987C3.78794 5.39987 3.5845 5.31562 3.43447 5.16564L0.234473 1.96564C0.0844966 1.81562 0.000244141 1.61217 0.000244141 1.40004C0.000244141 1.18791 0.0844966 0.984463 0.234473 0.834441Z'
        fill='#242546'
      />
    </svg>
  );
};

DownCaretIcon.prototype = {
  className: PropTypes.string,
  pathClassName: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md']),
};

export default DownCaretIcon;
