import React, { useEffect, useRef } from 'react';

const VerticalTabGroup = ({ tabs, onTabSelect, activeTab }) => {
  const groupRef = useRef(null);

  const getTabId = (tab) => `tab-${tab.value}`;

  useEffect(() => {
    const tabElement = groupRef.current.querySelector(
      `#${getTabId(activeTab)}`
    );
    groupRef.current.style.setProperty(
      '--tab-width',
      `${tabElement.offsetWidth}px`
    );
    groupRef.current.style.setProperty(
      '--tab-left',
      `${tabElement.offsetLeft}px`
    );
  }, [activeTab]);

  return (
    <div
      ref={groupRef}
      className='flex overflow-x-scroll rounded bg-gray-3 p-1 transition-all md:w-[130px] md:flex-col md:items-stretch'
    >
      {tabs.map((tab, index) => (
        <button
          id={getTabId(tab)}
          className={`rounded px-1 py-2 text-left text-sm text-gray-1 ${tab.value === activeTab.value && 'border-gray-1 bg-background text-primary shadow-sm'} `}
          key={index}
          onClick={() => onTabSelect(tab)}
        >
          {tab.name}
        </button>
      ))}
    </div>
  );
};

export default VerticalTabGroup;
