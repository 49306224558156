import React from 'react';

const PaginationButton = ({ onClick, selected, content, id, className }) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={`flex h-8 min-w-[2rem] items-center justify-center rounded-lg border border-gray-line text-sm font-semibold text-text transition-all hover:bg-gray-line [&>*]:mx-auto [&_*]:fill-text ${selected && 'bg-primary text-white hover:!bg-primary'} ${className || ''}`}
    >
      {content}
    </button>
  );
};

export default PaginationButton;
