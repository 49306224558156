import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  optionTypes: [],
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getOptionTypes = createAsyncThunk(
  'optionTypes/getOptionTypes',
  async (params) => {
    const response = await handleAPI('/platform/option_types', 'get', params);
    return response;
  }
);

const optionTypeSlice = createSlice({
  name: 'optionTypes',
  initialState,
  reducers: {
    editOptionType: (state, action) => {
      const optionTypes = state.optionTypes.map((optionType) =>
        optionType.id === action.payload.id ? action.payload : optionType
      );

      return {
        ...state,
        optionTypes,
        isLoading: false,
      };
    },
    addOptionType: (state, action) => {
      return {
        ...state,
        optionTypes: [action.payload, ...state.optionTypes],
        isLoading: false,
      };
    },
    deleteOptionType: (state, action) => {
      const optionTypes = state.optionTypes.filter(
        (optionType) => optionType.id !== action.payload
      );

      return {
        ...state,
        optionTypes,
        isLoading: false,
      };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getOptionTypes.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });
    builder.addCase(getOptionTypes.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        optionTypes: action.payload,
        totalPages: action?.metaData?.total_pages,
        totalCount: action?.metaData?.total_count,
      };
    });
    builder.addCase(getOptionTypes.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    });
  },
});
export const { editOptionType, addOptionType, deleteOptionType } =
  optionTypeSlice.actions;

export default optionTypeSlice.reducer;
