import React from 'react';
import { useSelector } from 'react-redux';
import { checkNestedValue } from '../../../utils/helper/helper';

const Privilege = ({ Key, children, skipCheck }) => {
  const abilities = useSelector((state) => state.auth.abilities);
  const hasPrivilege = skipCheck || checkNestedValue(abilities, Key);

  return <span>{hasPrivilege && children}</span>;
};

export default Privilege;
