import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  notifications: [],
  totalPages: null,
  totalCount: null,
  isLoading: true,
  error: null,
};

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async (params) => {
    const response = await handleAPI('platform/notifications', 'get', params);
    return response;
  }
);

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getNotifications.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getNotifications.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getNotifications.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export default notificationSlice.reducer;
