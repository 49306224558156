import React from 'react';

const DeleteActionIcon = ({ className }) => {
  return (
    <svg
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M1.5 4.49984H3.16667M3.16667 4.49984H16.5M3.16667 4.49984V16.1665C3.16667 16.6085 3.34226 17.0325 3.65482 17.345C3.96738 17.6576 4.39131 17.8332 4.83333 17.8332H13.1667C13.6087 17.8332 14.0326 17.6576 14.3452 17.345C14.6577 17.0325 14.8333 16.6085 14.8333 16.1665V4.49984H3.16667ZM5.66667 4.49984V2.83317C5.66667 2.39114 5.84226 1.96722 6.15482 1.65466C6.46738 1.3421 6.89131 1.1665 7.33333 1.1665H10.6667C11.1087 1.1665 11.5326 1.3421 11.8452 1.65466C12.1577 1.96722 12.3333 2.39114 12.3333 2.83317V4.49984M7.33333 8.6665V13.6665M10.6667 8.6665V13.6665'
        stroke='#5C89D4'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DeleteActionIcon;
