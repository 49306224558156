import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  campaignCategories: [],
  totalPages: null,
  totalCout: null,
  isLoading: true,
  error: null,
};

export const getCampaignCategory = createAsyncThunk(
  'campaign/getCampaignCategory',
  async (params) => {
    const response = await handleAPI(
      '/platform/campaign_categories',
      'get',
      params
    );
    return response;
  }
);

const campaignCategorySlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    editCampaignCategory: (state, action) => {
      return {
        ...state,
        campaignCategories: state.campaignCategories.map((category) =>
          category.id === action.payload.id ? action.payload : category
        ),
      };
    },
    deleteCampaignCategory: (state, action) => {
      return {
        ...state,
        campaignCategories: state.campaignCategories.filter(
          (category) => category.id !== action.payload
        ),
      };
    },
    addCampaignCategory: (state, action) => {
      return {
        ...state,
        campaignCategories: [action.payload, ...state.campaignCategories],
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCampaignCategory.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCampaignCategory.fulfilled, (state, action) => {
      state.isLoading = false;
      state.campaignCategories = action.payload;
      state.totalPages = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
    });
    builder.addCase(getCampaignCategory.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});
export const {
  editCampaignCategory,
  addCampaignCategory,
  deleteCampaignCategory,
} = campaignCategorySlice.actions;

export default campaignCategorySlice.reducer;
