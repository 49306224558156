import React, { useEffect } from 'react';
import Button from '../../atoms/button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import paths from '../../../constants/paths';
import { getCookie } from '../../../utils/helper/helper';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = getCookie('token');

  useEffect(() => {
    if (!token) {
      navigate(paths.login, { state: { from: location }, replace: true });
    }
  }, [navigate, token, location]);

  const goBack = () => {
    window.history.state && window.history.state.idx > 0
      ? navigate(-1)
      : navigate('/');
  };

  return (
    <div className='flex h-screen flex-col items-center justify-center gap-5 p-3 text-center'>
      <p className='font-bold text-blue'>404 error</p>

      <p className='text-4xl font-bold'>{"We can't find that page"}</p>

      <p className='text-gray-1'>
        {"Sorry, the page you are looking for doesn't exist or has been moved."}
      </p>

      <div className='mt-6 flex flex-col items-center gap-5 md:flex-row'>
        <Button
          variant='ghost'
          icon={<ArrowLeftIcon />}
          title='Go back'
          onClickHandler={goBack}
        />

        <Button
          title='Take me home'
          onClickHandler={() => navigate('/')}
        />
      </div>
    </div>
  );
};

export default NotFound;
