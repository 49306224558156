import React from 'react';

const SendActionIcon = ({ className }) => {
  return (
    <svg
      width='20'
      height='18'
      viewBox='0 0 20 15'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M18.3334 2.5026C18.3334 1.58594 17.5834 0.835938 16.6667 0.835938H3.33335C2.41669 0.835938 1.66669 1.58594 1.66669 2.5026M18.3334 2.5026V12.5026C18.3334 13.4193 17.5834 14.1693 16.6667 14.1693H3.33335C2.41669 14.1693 1.66669 13.4193 1.66669 12.5026V2.5026M18.3334 2.5026L10 8.33594L1.66669 2.5026'
        stroke='#5C89D4'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SendActionIcon;
