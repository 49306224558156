import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  binRoutings: [],
  binRouting: {},
  pools: [],
  gateways: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getBinRoutings = createAsyncThunk(
  'binRouting/getBinRoutings',
  async (params) => {
    const response = await handleAPI('platform/bin_routings', 'get', params);
    return response;
  }
);

export const getBinRouting = createAsyncThunk(
  'binRouting/getBinRouting',
  async (id) => {
    const response = await handleAPI(`platform/bin_routings/${id}`, 'get');
    return response;
  }
);

export const getBinRoutingPools = createAsyncThunk(
  'binRouting/getBinRoutingPools',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/bin_routings/${id}/pools`,
      'get',
      params
    );
    return response;
  }
);

export const getBinRoutingGateways = createAsyncThunk(
  'binRouting/getBinRoutingGateways',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/bin_routings/${id}/gateways`,
      'get',
      params
    );
    return response;
  }
);

const binRoutingSlice = createSlice({
  name: 'binRouting',
  initialState,
  reducers: {
    deleteBinRouting: (state, action) => {
      state.binRoutings = state.binRoutings.filter(
        (binRouting) => binRouting.id !== action.payload
      );
    },
    editBinRoutingPool: (state, action) => {
      const pools = [...state.pools];
      const updatedRecord = action.payload;
      const index = pools.findIndex((pool) => pool.id === updatedRecord.id);
      if (index !== -1) {
        pools[index] = updatedRecord;
      }
      state.pools = pools;
      state.isLoading = false;
    },
    editBinRoutingGateway: (state, action) => {
      const gateways = [...state.gateways];
      const updatedRecord = action.payload;
      const index = gateways.findIndex(
        (gateway) => gateway.id === updatedRecord.id
      );
      if (index !== -1) {
        gateways[index] = updatedRecord;
      }
      state.gateways = gateways;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBinRoutings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBinRoutings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.binRoutings = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getBinRoutings.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getBinRouting.fulfilled, (state, action) => {
      state.isLoading = false;
      state.binRouting = action.payload;
    });
    builder.addCase(getBinRoutingPools.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBinRoutingPools.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pools = action.payload;
    });
    builder.addCase(getBinRoutingGateways.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getBinRoutingGateways.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gateways = action.payload;
    });
  },
});

export const { deleteBinRouting, editBinRoutingPool, editBinRoutingGateway } =
  binRoutingSlice.actions;

export default binRoutingSlice.reducer;
