export const PaginationOptions = {
  BASIC: [
    { key: '5', value: 5 },
    { key: '10', value: 10 },
    { key: '15', value: 15 },
    { key: '20', value: 20 },
  ],
  EXTENDED: [
    { key: '5', value: 5 },
    { key: '10', value: 10 },
    { key: '15', value: 15 },
    { key: '20', value: 20 },
    { key: '50', value: 50 },
    { key: '100', value: 100 },
    { key: '250', value: 250 },
    { key: '500', value: 500 },
  ],
};
