import React from 'react';
import NavigableTabGroup from './NavigableTabGroup';
import paths from '../../../constants/paths';

const tabs = [
  {
    name: 'Affiliates',
    value: 'default',
    path: paths.adminAffiliates,
  },
  {
    name: 'Scrub Settings',
    value: 'scrub',
    path: paths.scrubSettings,
  },
  {
    name: 'Prepaid BINS',
    value: 'prepaid',
    path: paths.prepaidBins,
  },
];

const AdminAffiliatesTabGroup = () => {
  return <NavigableTabGroup tabs={tabs} />;
};

export default AdminAffiliatesTabGroup;
