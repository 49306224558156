import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  passedValues: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getPassedValues = createAsyncThunk(
  'passedValues/getPassedValues',
  async ({ id, page, per_page }) => {
    const response = await handleAPI(
      `platform/affiliates/${id}/passed_values`,
      'get',
      { 'filter[s]': 'created_at asc', page, per_page }
    );
    return response;
  }
);

const passedValuesSlice = createSlice({
  name: 'passedValues',
  initialState,
  reducers: {
    deletePassedValues: (state, action) => {
      state.passedValues = state.passedValues.filter(
        (passedValue) => passedValue.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPassedValues.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPassedValues.fulfilled, (state, action) => {
      state.isLoading = false;
      state.passedValues = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getPassedValues.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { deletePassedValues } = passedValuesSlice.actions;

export default passedValuesSlice.reducer;
