import { configureStore } from '@reduxjs/toolkit';
import userSlice from './slices/admin/userSlice';
import authSlice from './slices/authSlice';
import themeSlice from './slices/themeSlice';
import toastrSlice from './slices/toastrSlice';
import accessDetailsSlice from './slices/admin/accessDetailsSlice';
import serializeMiddleware from './middleware/serializeMiddleware';
import verticalSlice from './slices/product/verticalSlice';
import optionTypeSlice from './slices/product/optionTypeSlice';
import productSlice from './slices/product/productSlice';
import logsSlice from './slices/admin/logsSlice';
import optionValuesSlice from './slices/product/optionValuesSlice';
import smtpSlice from './slices/admin/smtpSlice';
import campaignCategorySlice from './slices/campaign/campaignCategorySlice';
import campaignSlice from './slices/campaign/campaignSlice';
import currencySlice from './slices/campaign/currencySlice';
import affiliateSlice from './slices/admin/affiliateSlice';
import pixelSlice from './slices/admin/pixelSlice';
import corporationSlice from './slices/payment/corporationSlice';
import bankSlice from './slices/payment/bankSlice';
import poolSlice from './slices/payment/poolSlice';
import passedValueSlice from './slices/admin/passedValueSlice';
import publisherSlice from './slices/admin/publisherSlice';
import blacklistSlice from './slices/admin/blacklistSlice';
import binRoutingSlice from './slices/payment/binRoutingSlice';
import gatewaySlice from './slices/payment/gatewaySlice';
import customerSlice from './slices/customer/customerSlice';
import gatewayProfileSlice from './slices/payment/gatewayProfileSlice';
import fulfilmentHouseSlice from './slices/admin/fulfilmentSlice';
import fulfilmentSlice from './slices/order/fulfilmentSlice';
import clickSlice from './slices/order/clickSlice';
import subscriptionSlice from './slices/order/subscriptionSlice';
import orderSlice from './slices/order/orderSlice';
import testCardSlice from './slices/admin/testCardSlice';
import notificationSlice from './slices/account/notificationSlice';
import autorerunSlice from './slices/order/autorerunSlice';
import affiliateScrubSlice from './slices/admin/affiliateScrubSlice';
import repocMapperSlice from './slices/payment/repocMapperSlice';
import rebillMapperSlice from './slices/payment/rebillMapperSlice';
import webhookSlice from './slices/admin/webhookSlice';
import transactionSlice from './slices/order/transactionSlice';
import confirmationModalSlice from './slices/confirmationModalSlice';
import rebillPoolsSlice from './slices/payment/rebillPoolsSlice';
import whitelistSlice from './slices/admin/whitelistSlice';
import recentActivitySlice from './slices/account/recentActivitySlice';
import scrubSettingsBulkLogSlice from './slices/admin/scrubSettingsBulkLogSlice';
import cardBinsSlice from './slices/payment/cardBinsSlice';
import promptModalSlice from './slices/promptModalSlice';

export const store = configureStore({
  reducer: {
    users: userSlice,
    auth: authSlice,
    theme: themeSlice,
    toastr: toastrSlice,
    accessDetails: accessDetailsSlice,
    verticals: verticalSlice,
    optionTypes: optionTypeSlice,
    optionValues: optionValuesSlice,
    product: productSlice,
    logs: logsSlice,
    actionModal: confirmationModalSlice,
    promptModal: promptModalSlice,
    smtp: smtpSlice,
    campaign: campaignSlice,
    currency: currencySlice,
    campaignCategory: campaignCategorySlice,
    fulfilmentHouse: fulfilmentHouseSlice,
    affiliate: affiliateSlice,
    pixel: pixelSlice,
    corporations: corporationSlice,
    banks: bankSlice,
    pools: poolSlice,
    rebillPools: rebillPoolsSlice,
    passedValues: passedValueSlice,
    publisher: publisherSlice,
    blacklist: blacklistSlice,
    whitelist: whitelistSlice,
    binRouting: binRoutingSlice,
    cardBins: cardBinsSlice,
    gateway: gatewaySlice,
    customer: customerSlice,
    gatewayProfile: gatewayProfileSlice,
    order: orderSlice,
    fulfilment: fulfilmentSlice,
    click: clickSlice,
    subscription: subscriptionSlice,
    transaction: transactionSlice,
    testCard: testCardSlice,
    notification: notificationSlice,
    recentActivity: recentActivitySlice,
    autoRerun: autorerunSlice,
    affiliateScrub: affiliateScrubSlice,
    repocMapper: repocMapperSlice,
    rebillMapper: rebillMapperSlice,
    webhook: webhookSlice,
    scrubSettingsBulkLogs: scrubSettingsBulkLogSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      serializeMiddleware
    ),
});
