import React, { Suspense, lazy } from 'react';
import paths from '../paths';
import Loader from '../../components/atoms/loader/Loader';
const CampaignEditLayout = lazy(
  () => import('../../components/layout/campaign/CampaignEditLayout')
);
const CampaignAffiliates = lazy(
  () => import('../../components/pages/campaign/affiliate/CampaignAffiliates')
);
const CampaignCountries = lazy(
  () =>
    import(
      '../../components/pages/campaign/campaignCountries/CampaignCountries'
    )
);
const CampaignFulFilment = lazy(
  () =>
    import(
      '../../components/pages/campaign/campaignFulFilment/CampaignFulFilment'
    )
);
const AddEmail = lazy(
  () => import('../../components/pages/campaign/email/AddEmail')
);
const Emails = lazy(
  () => import('../../components/pages/campaign/email/Emails')
);
const AddGeneralDetails = lazy(
  () =>
    import('../../components/pages/campaign/generalDetail/AddGeneralDetails')
);
const Offers = lazy(
  () => import('../../components/pages/campaign/offer/Offers')
);
const AddOffer = lazy(
  () => import('../../components/pages/campaign/offer/AddOffer')
);
const AddUpsell = lazy(
  () => import('../../components/pages/campaign/upsell/AddUpsell')
);
const Upsells = lazy(
  () => import('../../components/pages/campaign/upsell/Upsells')
);
const Pixels = lazy(
  () => import('../../components/pages/campaign/pixels/Pixels')
);
const AddPixels = lazy(
  () => import('../../components/pages/campaign/pixels/AddPixel')
);

const createLazyComponent = (Component) => (
  <Suspense fallback={<Loader spinner />}>
    <Component />
  </Suspense>
);

export const campaignEditRoutes = (abilities) => {
  const createRoute = (path, Component) => ({
    path,
    element: createLazyComponent(Component),
  });

  return {
    path: paths.getCampaignEditLayout(),
    element: createLazyComponent(CampaignEditLayout),
    children: [
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.editCampaign(), AddGeneralDetails),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.countries(), CampaignCountries),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.offers(), Offers),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.newOffer(), AddOffer),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.editOffer(), AddOffer),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.upsells(), Upsells),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.campaignAffiliates(), CampaignAffiliates),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.fulfilments(), CampaignFulFilment),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.emails(), Emails),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.newUpsell(), AddUpsell),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.editUpsell(), AddUpsell),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.newEmail(), AddEmail),
      (abilities?.campaign?.write ?? false) &&
        createRoute(paths.editEmail(), AddEmail),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.campaingPixels(), Pixels),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.newCampaignPixel(), AddPixels),
      (abilities?.campaign?.read ?? false) &&
        createRoute(paths.editCampaignPixel(), AddPixels),
    ],
  };
};
