import React from 'react';
import LayoutNavItem from '../../atoms/LayoutNavItem/LayoutNavItem';

const LayoutNav = ({ items }) => {
  const { pathname } = window.location;

  return (
    <div className='flex h-max flex-wrap rounded-lg bg-gray-3 p-[2px] md:w-max md:flex-col'>
      {items
        .filter((item) => !item.hidden)
        .map((item, index) => (
          <LayoutNavItem
            key={index}
            title={item.title}
            href={item.href}
            isActive={pathname.startsWith(item.href)}
          />
        ))}
    </div>
  );
};

export default LayoutNav;
