import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  currencies: [],
  isLoading: true,
  error: null,
};

export const getCurrencies = createAsyncThunk(
  'currency/getCurrencies',
  async () => {
    const response = await handleAPI('/platform/currencies', 'get');
    return response;
  }
);

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCurrencies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currencies = action.payload.data;
    });
  },
});

export default currencySlice.reducer;
