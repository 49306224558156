import React from 'react';

const SpinnerIcon = ({ svgClassname, className }) => {
  return (
    <svg
      width='18'
      height='18'
      viewBox='0 0 32 32'
      fill='none'
      className={svgClassname}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.6666 5.33335V13.3334M30.6666 13.3334H22.6666M30.6666 13.3334L24.4799 7.52002C23.0469 6.0863 21.2741 5.03896 19.3268 4.47572C17.3796 3.91247 15.3214 3.85169 13.3443 4.29903C11.3672 4.74637 9.53565 5.68726 8.02054 7.03391C6.50543 8.38056 5.35614 10.0891 4.67992 12M1.33325 26.6667V18.6667M1.33325 18.6667H9.33325M1.33325 18.6667L7.51992 24.48C8.95291 25.9137 10.7257 26.9611 12.673 27.5243C14.6202 28.0876 16.6784 28.1483 18.6555 27.701C20.6326 27.2537 22.4642 26.3128 23.9793 24.9661C25.4944 23.6195 26.6437 21.911 27.3199 20'
        stroke='#171717'
        className={className}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default SpinnerIcon;
