import React from 'react';
import Tooltip from '../tooltip/tooltip';

const Label = ({ id, infoData, className, label, required, labelHidden }) => {
  return (
    <div className='flex items-end'>
      <label
        htmlFor={id}
        className={`mb-1 text-sm capitalize text-text ${className} ${labelHidden ? 'invisible' : ''}`}
      >
        {label} {required && <span className='text-error'>*</span>}
      </label>
      {infoData && (
        <Tooltip
          infoData={infoData}
          className='ml-4'
        />
      )}
    </div>
  );
};

export default Label;
