import React from 'react';
import { Link } from 'react-router-dom';
import Privilege from '../../atoms/privilege/Privilege';
import TableCell from '../../atoms/tableCell/TableCell';
import TableActionButton from '../../atoms/TableActionButton/TableActionButton';
import Tooltip from '../../atoms/tooltip/tooltip';

const TableActionButtons = ({
  rowTitle,
  editUrl,
  onEditClick,
  onCopyClick,
  onDeleteClick,
  editPrivilege,
  copyPrivilege,
  deletePrivilege,
  sendMailPrivilege,
  onSendMailClick,
  skipEditPrivilege,
  skipCopyPrivilege,
  skipDeletePrivilege,
  skipSendMailPrivilege,
  disableEdit,
  disableCopy,
  disableDelete,
  disableSendMail,
}) => {
  const editButton = (
    <>
      {editUrl ? (
        <Link to={editUrl}>
          <TableActionButton
            type='edit'
            onClick={onEditClick}
            disabled={disableEdit}
          />
        </Link>
      ) : (
        <TableActionButton
          type='edit'
          onClick={onEditClick}
          disabled={disableEdit}
        />
      )}
    </>
  );

  const copyButton = (
    <TableActionButton
      type='copy'
      onClick={onCopyClick}
      disabled={disableCopy}
    />
  );

  const deleteButton = (
    <TableActionButton
      type='delete'
      onClick={onDeleteClick}
      disabled={disableDelete}
    />
  );

  const sendMailButton = (
    <TableActionButton
      type='sendMail'
      onClick={onSendMailClick}
      disable={disableSendMail}
    />
  );

  const buttons = (
    <>
      {(sendMailPrivilege || skipSendMailPrivilege) && (
        <Privilege
          Key={sendMailPrivilege}
          skipCheck={skipSendMailPrivilege}
        >
          <Tooltip
            infoData={{
              title: `Send test mail`,
              tooltip: sendMailButton,
              position: 'tooltip-top',
            }}
          />
        </Privilege>
      )}
      {(editPrivilege || skipEditPrivilege) && (
        <Privilege
          Key={editPrivilege}
          skipCheck={skipEditPrivilege}
        >
          <Tooltip
            infoData={{
              title: `Edit ${rowTitle}`,
              tooltip: editButton,
              position: 'tooltip-top',
            }}
          />
        </Privilege>
      )}
      {(copyPrivilege || skipCopyPrivilege) && (
        <Privilege
          Key={copyPrivilege}
          skipCheck={skipCopyPrivilege}
        >
          <Tooltip
            infoData={{
              title: `Copy ${rowTitle}`,
              tooltip: copyButton,
              position: 'tooltip-top',
            }}
          />
        </Privilege>
      )}
      {(deletePrivilege || skipDeletePrivilege) && (
        <Privilege
          Key={deletePrivilege}
          skipCheck={skipDeletePrivilege}
        >
          <Tooltip
            infoData={{
              title: `Delete ${rowTitle}`,
              tooltip: deleteButton,
              position: 'tooltip-top',
            }}
          />
        </Privilege>
      )}
    </>
  );

  return (
    <TableCell className='max-w-[15%] border-l border-gray-line'>
      <div className='dropdown-left dropdown flex md:hidden'>
        <div
          tabIndex='0'
          role='button'
          className='flex h-full rotate-90 flex-col px-6 py-3 font-bold leading-[6px]'
        >
          ...
        </div>
        <div
          tabIndex='0'
          className='dropdown-content z-[1] flex h-min -translate-y-[11px] translate-x-3 rounded-box border border-gray-2 bg-background p-2 shadow'
        >
          {buttons}
        </div>
      </div>

      <div className='hidden md:block'>{buttons}</div>
    </TableCell>
  );
};

export default TableActionButtons;
