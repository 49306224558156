import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  heading: '',
  description: '',
  closeButtonText: 'Cancel',
  confirmButtonText: 'Confirm',
  inputPlaceholder: '',
  inputRegex: undefined,
  inputError: '',
  initialValue: '',
  onConfirm: () => undefined,
  onClose: () => undefined,
  onCancel: () => undefined,
};

const promptModalSlice = createSlice({
  name: 'promptModal',
  initialState,
  reducers: {
    setPrompModalContent: (state, action) => ({ ...state, ...action.payload }),
    resetPrompModalContent: () => initialState,
  },
});

export const { setPrompModalContent, resetPrompModalContent } =
  promptModalSlice.actions;

export const openPromptModal = (payload) => (dispatch) => {
  dispatch(setPrompModalContent(payload));
  window.promptModal.showModal();
};

export const closePromptModal = () => (dispatch) => {
  window.promptModal.close();
  dispatch(resetPrompModalContent());
};

export default promptModalSlice.reducer;
