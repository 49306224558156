import React from 'react';

const PlusIcon = ({ className, size = 'md' }) => {
  const sizes = {
    sm: { width: '12', height: '12' },
    md: { width: '16', height: '16' },
  };

  return (
    <svg
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M8 1V15M1 8H15'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PlusIcon;
