import React from 'react';

const CopyActionIcon = ({ className }) => {
  return (
    <svg
      width='20'
      height='19'
      viewBox='0 0 20 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={className}
        d='M4.16669 11.9998H3.33335C2.89133 11.9998 2.4674 11.8242 2.15484 11.5117C1.84228 11.1991 1.66669 10.7752 1.66669 10.3332V2.83317C1.66669 2.39114 1.84228 1.96722 2.15484 1.65466C2.4674 1.3421 2.89133 1.1665 3.33335 1.1665H10.8334C11.2754 1.1665 11.6993 1.3421 12.0119 1.65466C12.3244 1.96722 12.5 2.39114 12.5 2.83317V3.6665M9.16669 6.99984H16.6667C17.5872 6.99984 18.3334 7.74603 18.3334 8.6665V16.1665C18.3334 17.087 17.5872 17.8332 16.6667 17.8332H9.16669C8.24621 17.8332 7.50002 17.087 7.50002 16.1665V8.6665C7.50002 7.74603 8.24621 6.99984 9.16669 6.99984Z'
        stroke='#5C89D4'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CopyActionIcon;
