import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  pools: [],
  pool: {},
  gateways: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getPools = createAsyncThunk('pools/getPools', async (params) => {
  const response = await handleAPI('/platform/pools', 'get', params);
  return response;
});

export const getPool = createAsyncThunk('pools/getPool', async (id) => {
  const response = await handleAPI(`/platform/pools/${id}`, 'get');
  return response;
});

export const getPoolGateways = createAsyncThunk(
  'pools/getPoolGateways',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/pools/${id}/gateways`,
      'get',
      params
    );
    return response;
  }
);

const poolSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    editPoolGateway: (state, action) => {
      const gateways = [...state.gateways];
      const updatedRecord = action.payload;
      const index = gateways.findIndex(
        (gateway) => gateway.id === updatedRecord.id
      );
      if (index !== -1) {
        gateways[index] = updatedRecord;
      }
      state.gateways = gateways;
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPools.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPools.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pools = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getPool.fulfilled, (state, action) => {
      state.isLoading = false;
      state.pool = action.payload;
    });
    builder.addCase(getPoolGateways.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPoolGateways.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gateways = action.payload;
    });
    builder.addCase(getPools.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
  },
});

export const { editPoolGateway } = poolSlice.actions;

export default poolSlice.reducer;
