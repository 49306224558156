import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  gateways: [],
  gateway: {},
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getGateways = createAsyncThunk(
  'gateways/getGateways',
  async (params) => {
    const response = await handleAPI('platform/gateways', 'get', params);
    return response;
  }
);

export const getGateway = createAsyncThunk(
  'gateways/getGateway',
  async ({ id, params }) => {
    const response = await handleAPI(`/platform/gateways/${id}`, 'get', params);
    return response;
  }
);

export const toggleGateway = createAsyncThunk(
  'gateways/toggleGateway',
  async ({ id, params, enabled }) => {
    const data = { gateway: { enabled } };
    const response = await handleAPI(
      `platform/gateways/${id}`,
      'put',
      params,
      data
    );
    return response;
  }
);

const gatewaySlice = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    deleteGateway: (state, action) => {
      state.gateways = state.gateways.filter(
        (gateway) => gateway.id !== action.payload
      );
    },
    resetGateway: (state) => {
      state.gateway = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGateways.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getGateways.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gateways = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getGateways.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getGateway.fulfilled, (state, action) => {
      state.isLoading = false;
      state.gateway = action.payload;
    });
    builder.addCase(toggleGateway.fulfilled, (state, action) => {
      const gateways = [...state.gateways];
      const updatedRecord = action.payload;
      const index = gateways.findIndex(
        (gateway) => gateway.id === updatedRecord.id
      );
      if (index !== -1) {
        gateways[index] = updatedRecord;
      }
      state.gateways = gateways;
    });
  },
});

export const { deleteGateway, editGateway, resetGateway } =
  gatewaySlice.actions;

export default gatewaySlice.reducer;
