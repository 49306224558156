import React, { useState, useMemo } from 'react';
import { usePopper } from 'react-popper';
import { createPortal } from 'react-dom';

const Tooltip = ({ infoData, className, bodyClassName }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const placementMap = {
    'tooltip-top': 'top',
    'tooltip-bottom': 'bottom',
    'tooltip-left': 'left',
    'tooltip-right': 'right',
  };

  const placement = placementMap[infoData.position] || 'bottom';

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
  });

  const portalTarget = useMemo(() => {
    if (referenceElement) {
      const modalElement = referenceElement.closest('.modal');
      return modalElement || document.body;
    }
    return document.body;
  }, [referenceElement]);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div
        className={`inline-block cursor-help ${className}`}
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {infoData.tooltip || (
          <span className='material-symbols-outlined cursor-help text-large text-primary'>
            info
          </span>
        )}
      </div>

      {showTooltip &&
        portalTarget &&
        createPortal(
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={`z-50 rounded-lg bg-gray-1 px-3 py-2 text-xs text-white ${bodyClassName}`}
          >
            {infoData?.title && (
              <p className='border-border text-center !text-xs font-semibold'>
                {infoData.title}
              </p>
            )}

            <div>{infoData?.content}</div>

            {infoData.warning && (
              <div className='flex items-center gap-1 p-2'>
                <p className='material-symbols-outlined filled-icon text-danger'>
                  warning
                </p>
                <p className='text-danger'>{infoData.warning}</p>
              </div>
            )}
          </div>,
          portalTarget
        )}
    </>
  );
};

export default Tooltip;
