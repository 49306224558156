import React from 'react';
import NavigableTabGroup from './NavigableTabGroup';
import paths from '../../../constants/paths';

const tabs = [
  {
    name: 'Bin Routing',
    value: 'bin-routings',
    path: paths.binRoutings,
  },
  {
    name: 'International BINs',
    value: 'card-bins',
    path: paths.cardBins,
  },
];

const BinRoutingsTabGroup = () => {
  return <NavigableTabGroup tabs={tabs} />;
};

export default BinRoutingsTabGroup;
