import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  affiliateScrubs: [],
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getAffiliateScrubs = createAsyncThunk(
  'affiliateScrubs/getAffiliateScrubs',
  async ({ id, params }) => {
    const response = await handleAPI(
      `platform/affiliates/${id}/scrub_settings`,
      'get',
      params
    );
    return response;
  }
);

export const deleteAffiliateScrub = createAsyncThunk(
  'affiliateScrub/deleteAffiliateScrub',
  async ({ affiliateId, scrubId }) => {
    return handleAPI(
      `platform/affiliates/${affiliateId}/scrub_settings/${scrubId}`,
      'delete',
      undefined
    );
  }
);

const affiliateScrubSlice = createSlice({
  name: 'affiliateScrub',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAffiliateScrubs.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAffiliateScrubs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.affiliateScrubs = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getAffiliateScrubs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(deleteAffiliateScrub.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(deleteAffiliateScrub.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload?.status === 200) {
        state.affiliateScrubs = state.affiliateScrubs.filter(
          (scrub) => scrub.id !== action.meta.arg.scrubId
        );
      }
    });
    builder.addCase(deleteAffiliateScrub.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export default affiliateScrubSlice.reducer;
