import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  optionValues: [],
  totalPage: null,
  isLoading: true,
  error: null,
};

export const getOptionValues = createAsyncThunk(
  'optionValues/getOptionValues',
  async (params) => {
    const response = await handleAPI('/platform/option_types', 'get', params);
    return response;
  }
);

const optionTypeSlice = createSlice({
  name: 'optionValues',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getOptionValues.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    });

    builder.addCase(getOptionValues.fulfilled, (state, action) => {
      return {
        ...state,
        isLoading: false,
        optionValues: action.payload,
        totalPage: action?.metaData?.total_pages,
      };
    });
    builder.addCase(getOptionValues.rejected, (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    });
  },
});

export default optionTypeSlice.reducer;
