import React from 'react';

const TableCell = ({ classname, children, isEmpty, ...props }) => {
  const showContent = children || children === 0;

  return (
    <td
      className={`px-2 py-[0.625rem] text-sm text-text ${classname ?? ''}`}
      {...props}
    >
      {showContent || isEmpty ? children : '---'}
    </td>
  );
};

export default TableCell;
