import paths from 'constants/paths';
import { BACKEND_URL } from 'env';
import { Logout } from 'redux/slices/authSlice';
import { showToastr } from 'redux/slices/toastrSlice';
import { ODINFORGE_URL } from 'utils/api/odinForge';
import { getCookie } from 'utils/helper/helper';

export const getHandlerKey = (url) => (url ? new URL(url).hostname : undefined);

export const RequestHandlers = {
  [getHandlerKey(BACKEND_URL)]: {
    onFulfilled: (request) => {
      if (!request.headers['Authorization'] && getCookie('token')) {
        request.headers['Authorization'] = getCookie('token');
      }

      return request;
    },
  },

  Default: {
    onFulfilled: (request) => request,
    onRejected: (error) => console.error(error),
  },
};

export const ResponseHandlers = {
  [getHandlerKey(BACKEND_URL)]: {
    onFulfilled: (response, dispatch) => {
      if (
        (response.status === 200 || response.status === 201) &&
        response.data?.meta?.message !== undefined &&
        response.data?.meta?.message !== null
      ) {
        dispatch(
          showToastr({ message: response.data?.meta?.message, type: 'success' })
        );
      }

      return response;
    },

    onRejected: (error, dispatch) => {
      if (error.response.status === 404) {
        window.location.href = '/error';
      } else {
        dispatch(
          showToastr({
            message: error.response?.data?.error ?? error.message,
            type: 'error',
          })
        );
      }

      if (
        error.response.status === 401 ||
        error.response.data.error.includes('Signature has expired')
      ) {
        dispatch(Logout());
        window.location.href = paths.login;
      } else {
        throw error;
      }
    },
  },

  [getHandlerKey(ODINFORGE_URL)]: {
    onFulfilled: (response, dispatch) => {
      dispatch(showToastr({ message: 'OdinForge: Success', type: 'success' }));

      return response;
    },

    onRejected: (error, dispatch) => {
      dispatch(
        showToastr({
          message: `OdinForge: ${error.response.data.message}`,
          type: 'error',
        })
      );
    },
  },

  Default: {
    onFulfilled: (response) => response,
    onRejected: (error) => console.error(error),
  },
};
