import React from 'react';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { styled } from '@mui/material/styles';
import moment from 'moment';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'isInRange' &&
    prop !== 'isStart' &&
    prop !== 'isEnd' &&
    prop !== 'isSingleSelected' &&
    prop !== 'isToday',
})(({ theme, isInRange, isStart, isEnd, isSingleSelected, isToday }) => ({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 0,
  ...(isSingleSelected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: '50%',
  }),
  ...(isInRange && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  }),
  ...(isStart &&
    !isSingleSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    }),
  ...(isEnd &&
    !isSingleSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      borderTopRightRadius: '50%',
      borderBottomRightRadius: '50%',
    }),
  ...(isToday && {
    boxShadow: `0 0 0 1px ${theme.palette.primary.dark} inset`,
  }),
  ...(isToday &&
    !isSingleSelected &&
    !isInRange &&
    !isStart &&
    !isEnd && {
      boxShadow: `0 0 0 1px ${theme.palette.primary.dark} inset`,
      borderRadius: '50%',
    }),
}));

const CustomDay = (props) => {
  const {
    day,
    startDate,
    endDate,
    outsideCurrentMonth,
    today,
    sx,
    selectedDate,
    ...other
  } = props;

  const dayMoment = moment(day);

  const isInRange =
    startDate && endDate
      ? dayMoment.isBetween(startDate, endDate, 'day', '[]')
      : false;
  const isStart = startDate ? dayMoment.isSame(startDate, 'day') : false;
  const isEnd = endDate ? dayMoment.isSame(endDate, 'day') : false;

  const isSelectedDate = selectedDate
    ? dayMoment.isSame(selectedDate, 'day')
    : false;
  const isSingleSelected =
    isSelectedDate ||
    (startDate && endDate && startDate.isSame(endDate, 'day') && isStart);

  return (
    <CustomPickersDay
      {...other}
      day={day}
      disableMargin
      outsideCurrentMonth={outsideCurrentMonth}
      isInRange={isInRange}
      isStart={isStart}
      isEnd={isEnd}
      isToday={today}
      sx={sx}
      isSingleSelected={isSingleSelected}
    />
  );
};

export default CustomDay;
