import React from 'react';

const HomeSkeleton = () => {
  return (
    <div className='screenbackground h-screen'>
      <div className='grid grid-cols-12'>
        <div className='col-start-1 col-end-2'>
          <div className='sidemenu h-screen overflow-auto'>
            <div className='sidemenu sticky top-0 py-7 lg:w-[5rem] xl:w-auto 2xl:w-[auto]'>
              <div className='skeleton mb-2 h-8 w-full'></div>
            </div>
            {[...Array(6)].map((_, index) => (
              <div key={index}>
                <div className='skeleton mb-2 h-20 w-full'></div>
              </div>
            ))}
          </div>
        </div>
        <div className='col-start-2 col-end-13 flex h-screen flex-col'>
          <div className='header-shadow background navbar z-50 flex justify-end gap-2 px-4'>
            <div className='flex-none'>
              <div className='skeleton mr-4 h-8 w-8 shrink-0 rounded-full'></div>
              <div className='skeleton mr-4 h-8 w-8 shrink-0 rounded-full'></div>
              <div>
                <div className='skeleton mb-1 h-2 w-20'></div>
                <div className='skeleton h-2 w-10'></div>
              </div>
              <div className='skeleton ml-2 h-8 w-8 shrink-0 rounded-full'></div>
            </div>
          </div>
          <div className='header-shadow background z-20 px-4 py-2'>
            <div className='breadcrumbs lg:text-[12px] xl:text-small'>
              <ul>
                <li>
                  <div className='skeleton h-4 w-20'></div>
                </li>
                <li>
                  <div className='skeleton h-4 w-20'></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSkeleton;
