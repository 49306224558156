import { useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { RequestHandlers, ResponseHandlers } from './interceptors';
import { getHandlerKey } from './interceptors';

const useAxiosPrivate = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (req) =>
        RequestHandlers[getHandlerKey(req.url)]?.onFulfilled?.(req) ??
        RequestHandlers.Default.onFulfilled(req),

      (err) =>
        RequestHandlers[getHandlerKey(err.url)]?.onRejected?.(err) ??
        RequestHandlers.Default.onRejected(err)
    );

    const responseInterceptor = axios.interceptors.response.use(
      (res) =>
        ResponseHandlers[getHandlerKey(res.request.responseURL)]?.onFulfilled?.(
          res,
          dispatch
        ) ?? RequestHandlers.Default.onFulfilled(res),

      (err) =>
        ResponseHandlers[getHandlerKey(err.request.responseURL)]?.onRejected?.(
          err,
          dispatch
        ) ?? RequestHandlers.Default.onRejected(err)
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [dispatch]);
};

export default useAxiosPrivate;
