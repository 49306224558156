import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  fulfilments: [],
  fulfilment: {},
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getFulfilments = createAsyncThunk(
  'fulfilments/getFulfilments',
  async (params) => {
    const response = await handleAPI(
      'platform/fulfillment_houses',
      'get',
      params
    );
    return response;
  }
);

export const getFulfilment = createAsyncThunk(
  'fulfilments/getFulfilment',
  async (id, params) => {
    const response = await handleAPI(
      `platform/fulfillment_houses/${id}`,
      'get',
      params
    );
    return response;
  }
);

const fulfilmentHouseSlice = createSlice({
  name: 'fulfilments',
  initialState,
  reducers: {
    deleteFulfilment: (state, action) => {
      state.fulfilments = state.fulfilments.filter(
        (fulfilment) => fulfilment.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFulfilments.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFulfilments.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fulfilments = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getFulfilments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getFulfilment.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFulfilment.fulfilled, (state, action) => {
      state.isLoading = false;
      state.fulfilment = action.payload;
    });
  },
});

export const { deleteFulfilment } = fulfilmentHouseSlice.actions;

export default fulfilmentHouseSlice.reducer;
