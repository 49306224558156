import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handleAPI } from '../../../utils/api/api';

const initialState = {
  subscriptions: [],
  subscription: {},
  totalPage: null,
  totalCount: null,
  count: null,
  isLoading: true,
  error: null,
};

export const getSubscriptions = createAsyncThunk(
  'subscriptions/getSubscriptions',
  (params) => {
    return handleAPI('platform/subscriptions', 'get', params);
  }
);

export const getSubscription = createAsyncThunk(
  'subscriptions/getSubscription',
  ({ number, params }) => {
    return handleAPI(`platform/subscriptions/${number}`, 'get', params);
  }
);

const subscriptionSlice = createSlice({
  name: 'clicks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubscriptions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscriptions.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscriptions = action.payload;
      state.totalPage = action?.metaData?.total_pages;
      state.totalCount = action?.metaData?.total_count;
      state.count = action?.metaData?.count;
    });
    builder.addCase(getSubscriptions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    });
    builder.addCase(getSubscription.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSubscription.fulfilled, (state, action) => {
      state.isLoading = false;
      state.subscription = action.payload;
      state.totalPage = action?.metaData?.total_pages;
    });
  },
});

export default subscriptionSlice.reducer;
