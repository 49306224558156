import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://303440926a7e696977fe7dd20764c67c@o4505946427555840.ingest.sentry.io/4506495604490240',
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        /^https:\/\/[a-zA-Z0-9-]+\.staging\.swisspriv\.com\/.*/,
        /^https:\/\/[a-zA-Z0-9-]*\.swisspriv\.com\/.*/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback={<p>loading...</p>}>
        <App />
      </Suspense>
    </Provider>
  </React.StrictMode>
);
