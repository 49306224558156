import moment from 'moment/moment';

export const DateRangeOptions = {
  TODAY: {
    id: 'today',
    name: 'Today',
    start: moment().startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_WEEK: {
    id: 'pastWeek',
    name: 'Past Week',
    start: moment().subtract(1, 'weeks').startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_TWO_WEEKS: {
    id: 'pastTwoWeeks',
    name: 'Past 2 Weeks',
    start: moment().subtract(2, 'weeks').startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_MONTH: {
    id: 'pastMonth',
    name: 'Past Month',
    start: moment().subtract(1, 'months').startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_THREE_MONTHS: {
    id: 'pastThreeMonths',
    name: 'Past 3 Months',
    start: moment().subtract(3, 'months').startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_SIX_MONTHS: {
    id: 'pastSixMonths',
    name: 'Past Six Months',
    start: moment().subtract(6, 'months').startOf('day'),
    end: moment().endOf('day'),
  },

  PAST_YEAR: {
    id: 'pastYear',
    name: 'Past Year',
    start: moment().subtract(1, 'years').startOf('day'),
    end: moment().endOf('day'),
  },

  YTD: {
    id: 'ytd',
    name: 'Year To Date',
    start: moment().startOf('year'),
    end: moment().endOf('day'),
  },

  CUSTOM: {
    id: 'customRange',
    name: 'Custom Range',
    start: null,
    end: null,
  },
};
