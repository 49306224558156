import React from 'react';

const DownCaretThinIcon = ({ svgClassName, pathClassName, size = 'md' }) => {
  const sizes = {
    sm: { width: '8', height: '6' },
    md: { width: '12', height: '8' },
    lg: { width: '14.4', height: '10.4' },
  };

  return (
    <svg
      className={svgClassName}
      width={sizes[size].width}
      height={sizes[size].height}
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className={pathClassName}
        d='M1 1.5L6 6.5L11 1.5'
        stroke='#242546'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DownCaretThinIcon;
