import React, { useRef } from 'react';
import ImportButton from '../../molecule/ImportButton/ImportButton';

const FileInputButton = ({
  handleFile,
  accept,
  id,
  title,
  className,
  disabled,
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    handleFile(fileUploaded);
  };
  return (
    <div className={className}>
      <ImportButton
        title={title}
        onClickHandler={handleClick}
        disabled={disabled}
      />

      <input
        type='file'
        value=''
        onChange={handleChange}
        accept={accept}
        id={id}
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        className='hidden'
      />
    </div>
  );
};

export default FileInputButton;
